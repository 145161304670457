import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { motion } from 'framer-motion';
import { Trash2, UserMinus, Download, UserPlus, Send } from 'lucide-react';
import { 
    getAllSubscriptions, 
    unsubscribeFromNewsletter, 
    deleteSubscription,
    subscribeToNewsletter,
    type NewsletterSubscription 
} from '@services/api/newsletter';
import NewsletterComposer from '@admin/components/NewsletterComposer';
import SMTPSettings from '@admin/components/SMTPSettings';

export default function NewsletterManagement() {
    const [subscriptions, setSubscriptions] = useState<NewsletterSubscription[]>([]);
    const [loading, setLoading] = useState(true);
    const [showSettings, setShowSettings] = useState(false);
    const [newEmail, setNewEmail] = useState('');

    useEffect(() => {
        loadSubscriptions();
    }, []);

    const loadSubscriptions = async () => {
        setLoading(true);
        try {
            const { data, error } = await getAllSubscriptions();
            if (error) throw error;
            setSubscriptions(data || []);
        } catch (error) {
            toast.error('Failed to load subscriptions');
            console.error('Load error:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleUnsubscribe = async (id: string) => {
        try {
            const { error } = await unsubscribeFromNewsletter(id);
            if (error) throw error;
            
            toast.success('Successfully unsubscribed');
            await loadSubscriptions();
        } catch (error) {
            toast.error('Failed to unsubscribe');
            console.error('Unsubscribe error:', error);
        }
    };

    const handleResubscribe = async (email: string) => {
        try {
            const { error } = await subscribeToNewsletter(email);
            if (error && error.type === 'error') throw error;
            
            toast.success('Successfully re-subscribed');
            await loadSubscriptions();
        } catch (error) {
            toast.error('Failed to re-subscribe');
            console.error('Re-subscribe error:', error);
        }
    };

    const handleDelete = async (id: string) => {
        try {
            const { error } = await deleteSubscription(id);
            if (error) throw error;
            
            toast.success('Successfully deleted subscription');
            await loadSubscriptions();
        } catch (error) {
            toast.error('Failed to delete subscription');
            console.error('Delete error:', error);
        }
    };

    const handleAddSubscriber = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!newEmail) return;

        try {
            const { error } = await subscribeToNewsletter(newEmail);
            if (error && error.type === 'error') throw error;
            
            if (error?.type === 'info') {
                toast.success(error.message);
            } else {
                toast.success('Successfully added new subscriber');
            }
            
            setNewEmail('');
            await loadSubscriptions();
        } catch (error) {
            toast.error('Failed to add subscriber');
            console.error('Add subscriber error:', error);
        }
    };

    const exportToCSV = () => {
        const headers = ['Email', 'Status', 'Subscribed At'];
        const csvContent = [
            headers.join(','),
            ...subscriptions.map(sub => 
                [sub.email, sub.status, new Date(sub.subscribed_at).toLocaleDateString()].join(',')
            )
        ].join('\n');

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `newsletter_subscribers_${new Date().toISOString().split('T')[0]}.csv`;
        link.click();
    };

    const activeSubscribers = subscriptions.filter(sub => sub.status === 'active').length;

    return (
        <div className="container mx-auto px-4 py-8 space-y-8">
            <div className="flex justify-between items-center">
                <h1 className="text-2xl font-bold text-gray-900 dark:text-white">Newsletter Management</h1>
                <div className="flex items-center gap-4">
                    <motion.button
                        whileHover={{ scale: 1.02 }}
                        whileTap={{ scale: 0.98 }}
                        onClick={() => setShowSettings(!showSettings)}
                        className="text-sm text-primary-500 hover:text-primary-600 dark:hover:text-primary-400"
                    >
                        {showSettings ? 'Hide SMTP Settings' : 'Show SMTP Settings'}
                    </motion.button>
                    <motion.button
                        whileHover={{ scale: 1.02 }}
                        whileTap={{ scale: 0.98 }}
                        onClick={exportToCSV}
                        className="flex items-center gap-2 px-4 py-2 bg-primary-500 text-white rounded-lg hover:bg-primary-600 transition-colors"
                    >
                        <Download className="w-4 h-4" />
                        Export CSV
                    </motion.button>
                </div>
            </div>

            {showSettings && <SMTPSettings />}

            <NewsletterComposer subscriberCount={activeSubscribers} />

            <div className="bg-white dark:bg-gray-800 shadow-xl rounded-lg overflow-hidden">
                <div className="p-6 border-b border-gray-200 dark:border-gray-700">
                    <div className="flex justify-between items-center">
                        <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                            Subscribers ({activeSubscribers} active)
                        </h2>
                        <form onSubmit={handleAddSubscriber} className="flex gap-2">
                            <input
                                type="email"
                                value={newEmail}
                                onChange={(e) => setNewEmail(e.target.value)}
                                placeholder="Add new subscriber..."
                                className="px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:text-white"
                                required
                            />
                            <motion.button
                                type="submit"
                                whileHover={{ scale: 1.02 }}
                                whileTap={{ scale: 0.98 }}
                                className="flex items-center gap-2 px-4 py-2 bg-primary-500 text-white rounded-lg hover:bg-primary-600 transition-colors"
                            >
                                <Send className="w-4 h-4" />
                                Add
                            </motion.button>
                        </form>
                    </div>
                </div>
                
                {loading ? (
                    <div className="flex justify-center items-center h-64">
                        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary-500"></div>
                    </div>
                ) : (
                    <div className="overflow-x-auto">
                        <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                            <thead className="bg-gray-50 dark:bg-gray-900">
                                <tr>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                                        Email
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                                        Status
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                                        Subscribed At
                                    </th>
                                    <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                                        Actions
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
                                {subscriptions.map((subscription) => (
                                    <tr key={subscription.id}>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-white">
                                            {subscription.email}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm">
                                            <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full
                                                ${subscription.status === 'active' 
                                                    ? 'bg-green-100 text-green-800 dark:bg-green-800 dark:text-green-100'
                                                    : 'bg-red-100 text-red-800 dark:bg-red-800 dark:text-red-100'}`}>
                                                {subscription.status}
                                            </span>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                                            {new Date(subscription.subscribed_at).toLocaleDateString()}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                            <div className="flex justify-end gap-2">
                                                {subscription.status === 'active' ? (
                                                    <motion.button
                                                        whileHover={{ scale: 1.1 }}
                                                        whileTap={{ scale: 0.9 }}
                                                        onClick={() => handleUnsubscribe(subscription.id)}
                                                        className="text-yellow-600 hover:text-yellow-700 dark:text-yellow-500 dark:hover:text-yellow-400"
                                                        title="Unsubscribe"
                                                    >
                                                        <UserMinus className="w-5 h-5" />
                                                    </motion.button>
                                                ) : (
                                                    <motion.button
                                                        whileHover={{ scale: 1.1 }}
                                                        whileTap={{ scale: 0.9 }}
                                                        onClick={() => handleResubscribe(subscription.email)}
                                                        className="text-green-600 hover:text-green-700 dark:text-green-500 dark:hover:text-green-400"
                                                        title="Re-subscribe"
                                                    >
                                                        <UserPlus className="w-5 h-5" />
                                                    </motion.button>
                                                )}
                                                <motion.button
                                                    whileHover={{ scale: 1.1 }}
                                                    whileTap={{ scale: 0.9 }}
                                                    onClick={() => handleDelete(subscription.id)}
                                                    className="text-red-600 hover:text-red-700 dark:text-red-500 dark:hover:text-red-400"
                                                    title="Delete"
                                                >
                                                    <Trash2 className="w-5 h-5" />
                                                </motion.button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </div>
    );
}
