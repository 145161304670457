import { supabase } from '@/lib/supabaseClient';
import type { AIConfig } from '@/services/config/ai';

// Convert camelCase to snake_case for database columns
const toDatabaseFormat = (settings: Partial<AIConfig>) => ({
  provider: settings.provider,
  image_provider: settings.imageProvider,
  endpoints: settings.endpoints,
  image_endpoints: settings.imageEndpoints,
  models: settings.models,
  image_models: settings.imageModels,
  selected_model: settings.selectedModel,
  selected_image_model: settings.selectedImageModel,
});

// Convert snake_case back to camelCase for application use
const toAppFormat = (data: any): AIConfig => ({
  provider: data.provider,
  imageProvider: data.image_provider,
  endpoints: data.endpoints,
  imageEndpoints: data.image_endpoints,
  models: data.models,
  imageModels: data.image_models,
  selectedModel: data.selected_model,
  selectedImageModel: data.selected_image_model,
});

export const aiSettingsApi = {
  /**
   * Get the current AI settings from Supabase
   */
  async getSettings(): Promise<AIConfig | null> {
    const { data, error } = await supabase
      .from('ai_settings')
      .select('*')
      .single();

    if (error) {
      console.error('Error fetching AI settings:', error);
      return null;
    }

    return toAppFormat(data);
  },

  /**
   * Update AI settings in Supabase
   */
  async updateSettings(settings: Partial<AIConfig>): Promise<void> {
    const dbSettings = toDatabaseFormat(settings);
    const { error } = await supabase
      .from('ai_settings')
      .update(dbSettings)
      .eq('id', 1); // We only have one settings record

    if (error) {
      console.error('Error updating AI settings:', error);
      throw error;
    }
  },

  /**
   * Initialize AI settings in Supabase if they don't exist
   */
  async initializeSettings(defaultConfig: AIConfig): Promise<void> {
    const { data } = await supabase
      .from('ai_settings')
      .select('id')
      .single();

    if (!data) {
      const dbConfig = toDatabaseFormat(defaultConfig);
      const { error: insertError } = await supabase
        .from('ai_settings')
        .insert([{ id: 1, ...dbConfig }]);

      if (insertError) {
        console.error('Error initializing AI settings:', insertError);
        throw insertError;
      }
    }
  }
};
