import { useState } from 'react';
import { Card } from '@/components/ui/card';
import { useToast } from '@/components/ui/use-toast';
import { supabase } from '@/lib/supabaseClient';

interface PromotionalAsset {
  id: string;
  title: string;
  description: string;
  category: 'social' | 'banner' | 'logo';
  file_url: string;
  dimensions?: string;
  format: string;
  downloads: number;
  created_at: string;
}

export default function AffiliateAssets() {
  const { toast } = useToast();
  const [assets, setAssets] = useState<Record<string, PromotionalAsset[]>>({
    social: [],
    banner: [],
    logo: [],
  });
  const [activeCategory, setActiveCategory] = useState<'social' | 'banner' | 'logo'>('social');

  const categories = [
    { id: 'social', label: 'Social Media Posts', icon: '📱' },
    { id: 'banner', label: 'Banners', icon: '🖼️' },
    { id: 'logo', label: 'Logos', icon: '✨' },
  ];

  const handleDownload = async (asset: PromotionalAsset) => {
    try {
      const { data } = await supabase
        .storage
        .from('promotional-assets')
        .createSignedUrl(asset.file_url, 60); // 60 seconds expiry

      if (data && data.signedUrl) {
        // Track the download
        await supabase
          .from('promotional_asset_downloads')
          .insert({
            asset_id: asset.id,
            downloaded_at: new Date().toISOString(),
          });

        // Update local state
        setAssets(prev => ({
          ...prev,
          [asset.category]: prev[asset.category].map(a =>
            a.id === asset.id ? { ...a, downloads: a.downloads + 1 } : a
          ),
        }));

        const link = document.createElement('a');
        link.href = data.signedUrl;
        link.download = asset.title;
        link.click();

        toast({
          title: 'Success',
          description: 'Download started',
        });
      } else {
        toast({
          title: 'Error',
          description: 'Unable to generate download link',
          variant: 'destructive',
        });
      }
    } catch (error) {
      console.error('Download error:', error);
      toast({
        title: 'Error',
        description: 'Failed to download asset',
        variant: 'destructive',
      });
    }
  };

  return (
    <div className="container mx-auto py-8">
      <h1 className="text-3xl font-bold mb-8">Promotional Assets</h1>

      {/* Category Navigation */}
      <div className="flex gap-4 mb-8">
        {categories.map(category => (
          <button
            key={category.id}
            onClick={() => setActiveCategory(category.id as typeof activeCategory)}
            className={`px-4 py-2 rounded-lg flex items-center gap-2 ${
              activeCategory === category.id
                ? 'bg-primary text-white'
                : 'bg-gray-100 hover:bg-gray-200'
            }`}
          >
            <span>{category.icon}</span>
            {category.label}
          </button>
        ))}
      </div>

      {/* Asset Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {assets[activeCategory].map((asset) => (
          <Card key={asset.id} className="p-4">
            <div className="aspect-video mb-4 bg-gray-100 rounded-lg overflow-hidden">
              {asset.format.startsWith('image/') ? (
                <img
                  src={`${import.meta.env.VITE_SUPABASE_URL}/storage/v1/object/public/promotional-assets/${asset.file_url}`}
                  alt={asset.title}
                  className="w-full h-full object-cover"
                />
              ) : (
                <div className="w-full h-full flex items-center justify-center text-4xl">
                  {asset.format.includes('pdf') ? '📄' : '📁'}
                </div>
              )}
            </div>

            <h3 className="font-bold mb-2">{asset.title}</h3>
            <p className="text-sm text-gray-500 mb-4">{asset.description}</p>

            {asset.dimensions && (
              <p className="text-xs text-gray-400 mb-2">
                Dimensions: {asset.dimensions}
              </p>
            )}

            <div className="flex items-center justify-between">
              <span className="text-xs text-gray-400">
                Downloads: {asset.downloads}
              </span>
              <button
                onClick={() => handleDownload(asset)}
                className="px-4 py-2 bg-primary text-white rounded-lg hover:bg-primary/90"
              >
                Download
              </button>
            </div>
          </Card>
        ))}
      </div>

      {assets[activeCategory].length === 0 && (
        <div className="text-center py-12 text-gray-500">
          No assets available in this category yet.
        </div>
      )}
    </div>
  );
}
