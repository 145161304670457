import { Outlet } from 'react-router-dom';
import { AffiliateNav } from '@/components/affiliate/AffiliateNav';

export function AffiliateLayout() {
  return (
    <div className="flex h-screen bg-gray-950">
      <AffiliateNav />
      <main className="flex-1 overflow-auto">
        <Outlet />
      </main>
    </div>
  );
}
