import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { Wand2, Loader2 } from 'lucide-react';
import { motion } from 'framer-motion';
import { sendMessage } from '@/services/core/api';
import { useAISettings } from '@/hooks/useAISettings';
import { Checkbox } from '@/components/ui/checkbox';
import { Label } from '@/components/ui/label';
import type { GeneratedBlogContent } from '@/types/blog';

interface AIBlogAssistantProps {
    onGenerate: (content: GeneratedBlogContent) => void;
    onTopicChange?: (topic: string) => void;
}

export default function AIBlogAssistant({ onGenerate, onTopicChange }: AIBlogAssistantProps) {
    const [topic, setTopic] = useState('');
    const [tone, setTone] = useState('professional');
    const [length, setLength] = useState('medium');
    const [generateImage, setGenerateImage] = useState(true);
    const [isGenerating, setIsGenerating] = useState(false);
    const { settings: aiSettings, loading: settingsLoading } = useAISettings();

    const handleTopicChange = (value: string) => {
        setTopic(value);
        onTopicChange?.(value);
    };

    const generateBlogPost = async () => {
        if (!topic) {
            toast.error('Please enter a topic');
            return;
        }

        if (settingsLoading) {
            toast.error('AI settings are still loading');
            return;
        }

        const selectedModel = aiSettings.selectedModel[aiSettings.provider];
        const model = aiSettings.models[aiSettings.provider].find(m => m.id === selectedModel);

        if (!model?.is_available) {
            toast.error('Selected AI model is not available');
            return;
        }

        setIsGenerating(true);
        try {
            const prompt = `You are a professional blog post writer. Generate a concise blog post about: "${topic}"

Instructions:
- Tone: ${tone}
- Length: ${length}
- Keep the content focused and concise
- Use markdown formatting for content (##, -, *, etc.)
- Ensure meta titles are under 60 characters
- Ensure meta descriptions are under 160 characters
- Format your response in valid JSON format with these fields:
  - title: A clear, engaging title
  - excerpt: A brief 2-3 sentence summary
  - content: The blog post content in markdown format
  - meta_title: SEO-optimized title (max 60 chars)
  - meta_description: SEO-optimized description (max 160 chars)
  ${generateImage ? '- image_prompt: A detailed visual description for image generation' : ''}

IMPORTANT: Your response must be a valid JSON object. Keep the content concise to avoid truncation.`;

            const response = await sendMessage(prompt);
            
            try {
                // Try to parse the entire response first
                try {
                    const blogData = JSON.parse(response.content);
                    if (isValidBlogData(blogData)) {
                        return processBlogData(blogData);
                    }
                } catch (e) {
                    // If direct parsing fails, try to repair the JSON
                    console.log('Direct parsing failed, attempting repair...');
                }

                // Try to extract and repair the JSON
                let jsonContent = response.content;
                
                // Look for the opening brace
                const startIndex = jsonContent.indexOf('{');
                if (startIndex === -1) {
                    throw new Error('No JSON object found in response');
                }

                // Look for the last complete field
                const fields = ['title', 'excerpt', 'content', 'meta_title', 'meta_description'];
                let lastCompleteField = '';
                let lastCompleteIndex = -1;

                fields.forEach(field => {
                    const fieldStart = jsonContent.indexOf(`"${field}": "`);
                    if (fieldStart !== -1) {
                        const fieldEnd = jsonContent.indexOf('",', fieldStart);
                        if (fieldEnd !== -1 && fieldEnd > lastCompleteIndex) {
                            lastCompleteField = field;
                            lastCompleteIndex = fieldEnd;
                        }
                    }
                });

                if (lastCompleteIndex !== -1) {
                    // Extract up to the last complete field and reconstruct the JSON
                    const partialJson = jsonContent.substring(startIndex, lastCompleteIndex + 2); // Include the closing quote and comma
                    
                    // Remove any trailing commas from the partial JSON
                    const cleanedJson = partialJson.replace(/,\s*$/, '');
                    
                    // Add any missing required fields with placeholder values
                    const remainingFields = fields.slice(fields.indexOf(lastCompleteField) + 1);
                    const missingFields = remainingFields.map(field => 
                        `"${field}": "${field === 'content' ? 'Content truncated...' : `Generated ${field}`}"`
                    ).join(',\n  ');

                    // Add image prompt if needed
                    const imagePrompt = generateImage ? `,\n  "image_prompt": "Generated image description"` : '';

                    // Reconstruct the JSON, ensuring no double commas
                    const repairedJson = `${cleanedJson}${missingFields ? ',\n  ' + missingFields : ''}${imagePrompt}}`;

                    try {
                        const blogData = JSON.parse(repairedJson);
                        if (isValidBlogData(blogData)) {
                            console.log('Successfully repaired and parsed JSON');
                            return processBlogData(blogData);
                        }
                    } catch (parseError) {
                        console.error('Failed to parse repaired JSON:', parseError);
                        console.error('Repaired JSON:', repairedJson);
                        
                        // If repair failed, try a simpler approach - just use what we have
                        try {
                            const simpleJson = `{
                                "title": "${jsonContent.match(/"title":\s*"([^"]+)"/)?.[1] || 'Generated title'}",
                                "excerpt": "${jsonContent.match(/"excerpt":\s*"([^"]+)"/)?.[1] || 'Generated excerpt'}",
                                "content": "${jsonContent.match(/"content":\s*"([^"]+)"/)?.[1] || 'Content truncated...'}",
                                "meta_title": "${jsonContent.match(/"meta_title":\s*"([^"]+)"/)?.[1] || 'Generated meta title'}",
                                "meta_description": "${jsonContent.match(/"meta_description":\s*"([^"]+)"/)?.[1] || 'Generated meta description'}"${
                                generateImage ? `,\n  "image_prompt": "Generated image description"` : ''
                            }}`;
                            
                            const simpleBlogData = JSON.parse(simpleJson);
                            if (isValidBlogData(simpleBlogData)) {
                                console.log('Successfully parsed simplified JSON');
                                return processBlogData(simpleBlogData);
                            }
                        } catch (simpleError) {
                            console.error('Failed to parse simplified JSON:', simpleError);
                            throw new Error('Failed to repair and parse the JSON response');
                        }
                    }
                }

                console.error('Raw response:', response.content);
                throw new Error('Could not find a complete JSON object in the response');
            } catch (error) {
                console.error('Error generating blog post:', error);
                toast.error(error instanceof Error ? error.message : 'Failed to generate blog post');
            }
        } catch (error) {
            console.error('Error generating blog post:', error);
            toast.error(error instanceof Error ? error.message : 'Failed to generate blog post');
        } finally {
            setIsGenerating(false);
        }
    };

    // Helper function to validate blog data
    const isValidBlogData = (data: any): data is GeneratedBlogContent => {
        type BlogField = keyof GeneratedBlogContent;
        const baseFields: BlogField[] = ['title', 'excerpt', 'content', 'meta_title', 'meta_description'];
        const requiredFields: BlogField[] = generateImage 
            ? [...baseFields, 'image_prompt']
            : baseFields;

        return requiredFields.every(field => typeof data[field] === 'string' && data[field].trim() !== '');
    };

    // Helper function to process and validate blog data
    const processBlogData = (blogData: GeneratedBlogContent) => {
        // Validate required fields
        type BlogField = keyof GeneratedBlogContent;
        const baseFields: BlogField[] = ['title', 'excerpt', 'content', 'meta_title', 'meta_description'];
        const requiredFields: BlogField[] = generateImage 
            ? [...baseFields, 'image_prompt']
            : baseFields;

        const missingFields = requiredFields.filter(field => !blogData[field]);
        if (missingFields.length > 0) {
            throw new Error(`Missing required fields: ${missingFields.join(', ')}`);
        }

        // Validate field lengths
        if (blogData.meta_title.length > 60) {
            blogData.meta_title = blogData.meta_title.substring(0, 57) + '...';
        }
        if (blogData.meta_description.length > 160) {
            blogData.meta_description = blogData.meta_description.substring(0, 157) + '...';
        }

        onGenerate(blogData);
        toast.success('Blog post generated successfully!');
        return true;
    };

    return (
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 mb-8"
        >
            <h2 className="text-xl font-bold mb-4 text-gray-900 dark:text-white">AI Blog Assistant</h2>
            
            <div className="space-y-4">
                <div>
                    <Label>Topic or Title</Label>
                    <input
                        type="text"
                        value={topic}
                        onChange={(e) => handleTopicChange(e.target.value)}
                        placeholder="Enter your blog topic or title"
                        className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-primary-500
                            dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                    />
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                        <Label>Tone</Label>
                        <select
                            value={tone}
                            onChange={(e) => setTone(e.target.value)}
                            className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-primary-500
                                dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                        >
                            <option value="professional">Professional</option>
                            <option value="casual">Casual</option>
                            <option value="friendly">Friendly</option>
                            <option value="formal">Formal</option>
                            <option value="humorous">Humorous</option>
                        </select>
                    </div>

                    <div>
                        <Label>Length</Label>
                        <select
                            value={length}
                            onChange={(e) => setLength(e.target.value)}
                            className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-primary-500
                                dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                        >
                            <option value="short">Short (~300 words)</option>
                            <option value="medium">Medium (~600 words)</option>
                            <option value="long">Long (~1000 words)</option>
                        </select>
                    </div>
                </div>

                <div className="flex items-center space-x-2">
                    <Checkbox 
                        id="generate-image"
                        checked={generateImage}
                        onCheckedChange={(checked) => setGenerateImage(checked as boolean)}
                    />
                    <Label 
                        htmlFor="generate-image"
                        className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                    >
                        Generate Featured Image
                    </Label>
                </div>

                <button
                    onClick={generateBlogPost}
                    disabled={isGenerating || settingsLoading || !topic}
                    className="w-full inline-flex justify-center items-center px-4 py-2 bg-primary-500 text-white rounded-lg
                        hover:bg-primary-600 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                    {isGenerating ? (
                        <>
                            <Loader2 className="w-5 h-5 mr-2 animate-spin" />
                            Generating...
                        </>
                    ) : (
                        <>
                            <Wand2 className="w-5 h-5 mr-2" />
                            Generate Blog Post
                        </>
                    )}
                </button>
            </div>
        </motion.div>
    );
}
