import type { FC } from 'react';
import { useState, FormEvent, useEffect } from 'react';
import { useNavigate, useLocation, Link, useSearchParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import { toast } from 'react-hot-toast';
import { useAuth } from '@/hooks/useAuth';
import { Button } from '@/components/ui/button';
import { supabase } from '@/lib/supabaseClient';
import Navbar from '@/components/layout/Navbar';
import Footer from '@/components/layout/Footer';

const AffiliateLogin: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [resendingEmail, setResendingEmail] = useState(false);

  // Get the page user tried to visit before being redirected to login
  const from = location.state?.from?.pathname || '/affiliate/dashboard';

  useEffect(() => {
    // Check for email verification errors
    const error = searchParams.get('error');
    const errorDescription = searchParams.get('error_description');

    if (error === 'access_denied' && errorDescription?.includes('Email link is invalid or has expired')) {
      toast.error('Email verification link has expired. Please request a new one.');
    }
  }, [searchParams]);

  const resendVerificationEmail = async () => {
    if (!email) {
      toast.error('Please enter your email address');
      return;
    }

    setResendingEmail(true);
    try {
      const { error } = await supabase.auth.resend({
        type: 'signup',
        email,
        options: {
          emailRedirectTo: `${window.location.origin}/affiliate/login`
        }
      });

      if (error) throw error;
      toast.success('Verification email sent! Please check your inbox.');
    } catch (error) {
      console.error('Error resending verification email:', error);
      toast.error('Failed to send verification email. Please try again.');
    } finally {
      setResendingEmail(false);
    }
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      // Sign in the user
      const { data: authData, error: signInError } = await supabase.auth.signInWithPassword({
        email,
        password,
      });
      if (signInError) throw signInError;

      if (!authData.user || !authData.session) {
        throw new Error('No user data after login');
      }

      console.log('Auth user details:', {
        id: authData.user.id,
        email: authData.user.email
      });

      // Let's check all affiliate records to see what we have
      const { data: allAffiliates } = await supabase
        .from('affiliates')
        .select('*');

      // If we have an affiliate record but with a different user_id, update it
      if (allAffiliates && allAffiliates.length > 0) {
        const existingAffiliate = allAffiliates[0];
        
        if (existingAffiliate.user_id !== authData.user.id) {
          console.log('Updating affiliate record with current user_id');
          const { error: updateError } = await supabase
            .from('affiliates')
            .update({ user_id: authData.user.id })
            .eq('id', existingAffiliate.id);

          if (updateError) {
            console.error('Error updating affiliate user_id:', updateError);
            toast.error('Error updating affiliate record');
            return;
          }

          toast.success('Welcome back!');
          navigate(from, { replace: true });
          return;
        }
      }

      // Check if they are an affiliate
      const { data: affiliate, error: affiliateError } = await supabase
        .from('affiliates')
        .select('*')
        .eq('user_id', authData.user.id)
        .limit(1)
        .maybeSingle();

      if (affiliateError) {
        console.error('Affiliate lookup error:', affiliateError);
        toast.error('An error occurred while checking affiliate status');
        return;
      }

      if (!affiliate) {
        console.log('No affiliate record found');
        toast.success('Please apply to become an affiliate');
        navigate('/affiliate/apply');
        return;
      }

      if (affiliate.status === 'suspended') {
        toast.error('Your affiliate account has been suspended. Please contact support.');
        return;
      }

      toast.success('Welcome back!');
      navigate(from, { replace: true });
    } catch (error: any) {
      console.error('Login error:', error);
      if (error?.message?.includes('Email not confirmed')) {
        toast.error('Please verify your email before logging in');
        return;
      }
      toast.error('Invalid email or password');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900">
      <Navbar />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-32">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="max-w-md mx-auto"
        >
          <div className="bg-gray-800/50 backdrop-blur-lg rounded-xl p-8 shadow-lg border border-gray-700">
            <div className="text-center mb-8">
              <h2 className="text-3xl font-bold text-white mb-2">Welcome Back</h2>
              <p className="text-gray-400">
                Access your affiliate dashboard and track your earnings
              </p>
            </div>
            
            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-300 mb-2">
                  Email Address
                </label>
                <input
                  id="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full bg-gray-700/50 border border-gray-600 rounded-lg px-4 py-2.5 text-white focus:outline-none focus:ring-2 focus:ring-blue-500 transition-colors"
                  placeholder="you@example.com"
                  required
                />
              </div>

              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-300 mb-2">
                  Password
                </label>
                <input
                  id="password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full bg-gray-700/50 border border-gray-600 rounded-lg px-4 py-2.5 text-white focus:outline-none focus:ring-2 focus:ring-blue-500 transition-colors"
                  placeholder="••••••••"
                  required
                />
              </div>

              <Button
                type="submit"
                disabled={isLoading}
                variant="default"
                size="lg"
                className="w-full bg-primary-500 hover:bg-primary-600 text-white"
              >
                {isLoading ? (
                  <div className="flex items-center justify-center">
                    <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-white mr-3"></div>
                    Signing in...
                  </div>
                ) : (
                  'Sign In'
                )}
              </Button>
            </form>

            <div className="mt-6 space-y-4 text-center">
              <Link
                to="/affiliate/reset-password"
                className="block text-sm text-blue-400 hover:text-blue-300 transition-colors"
              >
                Forgot your password?
              </Link>

              <button
                onClick={resendVerificationEmail}
                disabled={resendingEmail}
                className="block w-full text-sm text-blue-400 hover:text-blue-300 transition-colors disabled:opacity-50"
              >
                {resendingEmail ? 'Sending...' : 'Resend verification email'}
              </button>

              <div className="text-gray-400">
                Not an affiliate yet?{' '}
                <Link
                  to="/affiliate/apply"
                  className="text-blue-400 hover:text-blue-300 transition-colors"
                >
                  Apply now
                </Link>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
      <Footer showNewsletter={false} />
    </div>
  );
};

export default AffiliateLogin;
