import { forwardRef, useImperativeHandle, useState } from 'react';
import { Wand2, Loader2 } from 'lucide-react';
import { toast } from 'react-hot-toast';
import { sendMessage } from '@/services/core/api';
import { generateImage, uploadGeneratedImage } from '@/services/api/image';
import { useAISettings } from '@/hooks/useAISettings';

interface ImageGeneratorProps {
    onImageGenerated: (url: string) => void;
    title?: string;
}

export interface ImageGeneratorRef {
    generateImage: (prompt: string) => Promise<string | null>;
}

const ImageGenerator = forwardRef<ImageGeneratorRef, ImageGeneratorProps>(({ onImageGenerated, title }, ref) => {
    const [isGenerating, setIsGenerating] = useState(false);
    const { settings: aiSettings, loading: settingsLoading } = useAISettings();

    const generateImageFromPrompt = async (prompt: string): Promise<string | null> => {
        if (!title?.trim()) {
            toast.error('Please enter a title in either the Title field or Topic field');
            return null;
        }

        if (settingsLoading) {
            toast.error('AI settings are still loading');
            return null;
        }

        const selectedModel = aiSettings.selectedModel[aiSettings.provider];
        const model = aiSettings.models[aiSettings.provider].find(m => m.id === selectedModel);

        if (!model?.is_available) {
            toast.error('Selected AI model is not available');
            return null;
        }

        setIsGenerating(true);
        try {
            // First, get the image generation prompt
            const promptResponse = await sendMessage(
                `You are an image generation assistant. Generate a DALL-E prompt for the following image description. Make it detailed and specific, focusing on style, mood, and visual elements. Keep it under 100 words. Respond ONLY with the prompt, no other text.

Description: ${prompt}
Title: ${title}`
            );

            // Generate the image using the refined prompt
            const { data, error } = await generateImage({
                prompt: promptResponse.content,
                size: '1024x1024',
                n: 1
            });

            if (error) throw new Error(error);
            if (!data?.[0]) throw new Error('No image generated');

            // Upload the generated image
            const timestamp = Date.now();
            const path = `generated/${timestamp}.png`;
            const { url: imageUrl, error: uploadError } = await uploadGeneratedImage(data[0], path);

            if (uploadError) throw new Error(uploadError);
            if (!imageUrl) throw new Error('Failed to get upload URL');

            onImageGenerated(imageUrl);
            return imageUrl;
        } catch (error) {
            console.error('Error generating image:', error);
            toast.error('Failed to generate image');
            return null;
        } finally {
            setIsGenerating(false);
        }
    };

    useImperativeHandle(ref, () => ({
        generateImage: generateImageFromPrompt
    }));

    return (
        <button
            onClick={() => generateImageFromPrompt('Generate an image that represents this blog post')}
            disabled={isGenerating || settingsLoading || !title?.trim()}
            title={!title?.trim() ? 'Please enter a title first' : ''}
            className="inline-flex items-center gap-2 px-4 py-2 bg-primary-500 text-white rounded-lg hover:bg-primary-600 disabled:opacity-50 disabled:cursor-not-allowed"
            type="button"
        >
            {isGenerating ? (
                <>
                    <Loader2 className="w-5 h-5 animate-spin" />
                    Generating...
                </>
            ) : (
                <>
                    <Wand2 className="w-5 h-5" />
                    Generate Image
                </>
            )}
        </button>
    );
});

ImageGenerator.displayName = 'ImageGenerator';

export default ImageGenerator;
