import { type FC } from 'react';
import useAuth from '@/hooks/useAuth';
import { useQuery } from '@tanstack/react-query';
import { motion } from 'framer-motion';
import PerformanceDashboard from '@/components/affiliate/PerformanceDashboard';
import { affiliateApi } from '@/services/api/affiliate';
import Footer from '@/components/layout/Footer';

const AffiliateDashboard: FC = () => {
    const { user } = useAuth();
    const { data: affiliate, isLoading } = useQuery({
        queryKey: ['affiliate', user?.id],
        queryFn: () => affiliateApi.getAffiliateDetails(user!.id),
        enabled: !!user
    });

    if (isLoading) return null;
    if (!affiliate?.id || !affiliate?.tier) return null;

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="min-h-screen bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900"
        >
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
                <div className="px-4 sm:px-0">
                    <h1 className="text-2xl font-semibold text-white">
                        Affiliate Dashboard
                    </h1>
                    <p className="mt-2 text-sm text-gray-400">
                        Track your performance and manage your affiliate account
                    </p>
                </div>

                <div className="mt-8">
                    <PerformanceDashboard
                        affiliateId={affiliate.id}
                        tierId={affiliate.tier}
                    />
                </div>
            </div>
            <Footer showNewsletter={false} />
        </motion.div>
    );
};

export default AffiliateDashboard;
