import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { CheckCircle, ChevronLeft } from 'lucide-react';
import Footer from '@/components/layout/Footer';
import Navbar from '@/components/layout/Navbar';

const AffiliateThankYouPage: React.FC = () => {
    return (
        <div className="min-h-screen bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900">
            <Navbar />
            <div className="container mx-auto px-4 pt-24 pb-12">
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    className="max-w-2xl mx-auto"
                >
                    <Link
                        to="/affiliate"
                        className="inline-flex items-center gap-2 text-gray-400 hover:text-primary-400 transition-colors duration-200 mb-8"
                    >
                        <ChevronLeft className="w-4 h-4" />
                        Back to Affiliate Program
                    </Link>

                    <div className="bg-white/5 backdrop-blur-xl border border-white/10 rounded-2xl p-8">
                        <motion.div
                            initial={{ scale: 0 }}
                            animate={{ scale: 1 }}
                            className="w-20 h-20 mx-auto mb-6 text-primary-500"
                        >
                            <CheckCircle className="w-full h-full" />
                        </motion.div>

                        <h1 className="text-3xl font-bold text-white mb-4">
                            Application Submitted Successfully!
                        </h1>
                        
                        <p className="text-gray-400 mb-8">
                            Thank you for applying to the ScholarTree Affiliate Program. We've received your application
                            and will review it carefully. You can expect to hear back from us within 2-3 business days.
                        </p>

                        <div className="space-y-4">
                            <h2 className="text-xl font-semibold text-white">What's Next?</h2>
                            <ul className="text-gray-400 space-y-2">
                                <li>Our team will review your application</li>
                                <li>You'll receive an email with our decision</li>
                                <li>If approved, we'll send you access to your affiliate dashboard</li>
                                <li>You can then start promoting ScholarTree and earning commissions</li>
                            </ul>
                        </div>

                        <div className="mt-8 p-4 bg-primary-500/10 border border-primary-500/20 rounded-lg">
                            <p className="text-primary-400">
                                Have questions? Contact our affiliate support team at{' '}
                                <a
                                    href="mailto:affiliates@scholartree.app"
                                    className="text-primary-500 hover:text-primary-400 transition-colors duration-200"
                                >
                                    affiliates@scholartree.app
                                </a>
                            </p>
                        </div>
                    </div>
                </motion.div>
            </div>
            <Footer showNewsletter={false} />
        </div>
    );
};

export default AffiliateThankYouPage;
