import { useState, FormEvent, useEffect } from 'react';
import { useNavigate, useSearchParams, Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { toast } from 'react-hot-toast';
import { Button } from '@/components/ui/button';
import { supabase } from '@/lib/supabaseClient';
import { CheckCircle } from 'lucide-react';

export default function AffiliateSignup() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState(false);
    const [tokenValid, setTokenValid] = useState(false);
    const [showVerificationMessage, setShowVerificationMessage] = useState(false);
    const [tokenData, setTokenData] = useState<{
        email: string;
        first_name: string;
        last_name: string;
        affiliate_id: string | null;
    } | null>(null);

    const [formData, setFormData] = useState({
        password: '',
        confirmPassword: ''
    });

    useEffect(() => {
        const token = searchParams.get('token');
        if (!token) {
            toast.error('Invalid signup link. Please request a new one.');
            navigate('/affiliate/login');
            return;
        }

        const validateToken = async () => {
            try {
                const { data, error } = await supabase
                    .from('affiliate_signup_tokens')
                    .select('email, first_name, last_name, affiliate_id, used_at, expires_at')
                    .eq('token', token)
                    .single();

                if (error) throw error;
                if (!data) {
                    toast.error('Invalid signup link. Please request a new one.');
                    navigate('/affiliate/login');
                    return;
                }

                // Check if token is expired or used
                const now = new Date();
                const expiresAt = new Date(data.expires_at);
                if (now > expiresAt || data.used_at) {
                    toast.error('This signup link has expired. Please request a new one.');
                    navigate('/affiliate/login');
                    return;
                }

                setTokenValid(true);
                setTokenData({
                    email: data.email,
                    first_name: data.first_name,
                    last_name: data.last_name,
                    affiliate_id: data.affiliate_id
                });
            } catch (error) {
                console.error('Error validating token:', error);
                toast.error('Error validating signup link. Please try again.');
                navigate('/affiliate/login');
            }
        };

        validateToken();
    }, [searchParams, navigate]);

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();

        if (!tokenValid || !tokenData) {
            toast.error('Invalid signup link. Please request a new one.');
            return;
        }

        if (formData.password !== formData.confirmPassword) {
            toast.error('Passwords do not match');
            return;
        }

        if (formData.password.length < 8) {
            toast.error('Password must be at least 8 characters long');
            return;
        }

        setLoading(true);

        try {
            // Create user account
            const { data: authData, error: authError } = await supabase.auth.signUp({
                email: tokenData.email,
                password: formData.password,
                options: {
                    data: {
                        first_name: tokenData.first_name,
                        last_name: tokenData.last_name
                    },
                    emailRedirectTo: `${window.location.origin}/affiliate/verify`
                }
            });

            if (authError) throw authError;

            // Mark token as used
            const token = searchParams.get('token');
            const { error: tokenError } = await supabase
                .from('affiliate_signup_tokens')
                .update({ used_at: new Date().toISOString() })
                .eq('token', token);

            if (tokenError) throw tokenError;

            // Update affiliate record with user_id if it exists
            if (tokenData.affiliate_id && authData.user) {
                const { error: affiliateError } = await supabase
                    .from('affiliates')
                    .update({ user_id: authData.user.id })
                    .eq('id', tokenData.affiliate_id);

                if (affiliateError) throw affiliateError;
            }

            setShowVerificationMessage(true);
            toast.success('Account created successfully!');
        } catch (error) {
            console.error('Error creating account:', error);
            toast.error('Error creating account. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    if (showVerificationMessage) {
        return (
            <div className="min-h-screen bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900 py-12">
                <div className="container mx-auto px-4">
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        className="max-w-2xl mx-auto"
                    >
                        <div className="bg-white/5 backdrop-blur-xl border border-white/10 rounded-2xl p-8 text-center">
                            <motion.div
                                initial={{ scale: 0 }}
                                animate={{ scale: 1 }}
                                className="w-20 h-20 mx-auto mb-6 text-primary-500"
                            >
                                <CheckCircle className="w-full h-full" />
                            </motion.div>

                            <h1 className="text-3xl font-bold text-white mb-4">Check Your Email</h1>
                            
                            <p className="text-gray-300 mb-6">
                                We've sent a verification email to <span className="text-primary-400">{tokenData?.email}</span>.
                                Please check your inbox and click the verification link to complete your account setup.
                            </p>

                            <div className="space-y-4 text-gray-300">
                                <p className="font-semibold">Next steps:</p>
                                <ol className="list-decimal list-inside space-y-2 text-left">
                                    <li>Open the email from ScholarTree</li>
                                    <li>Click the verification link</li>
                                    <li>Once verified, you can log in to your affiliate dashboard</li>
                                </ol>
                            </div>

                            <div className="mt-8 space-y-4">
                                <p className="text-gray-400">
                                    Didn't receive the email? Check your spam folder or click below to resend.
                                </p>
                                <button
                                    onClick={() => {
                                        if (tokenData?.email) {
                                            supabase.auth.resend({
                                                type: 'signup',
                                                email: tokenData.email,
                                                options: {
                                                    emailRedirectTo: `${window.location.origin}/affiliate/verify`
                                                }
                                            });
                                            toast.success('Verification email resent!');
                                        }
                                    }}
                                    className="px-6 py-2 bg-primary-500/20 text-primary-400 rounded-lg hover:bg-primary-500/30 transition-colors"
                                >
                                    Resend Verification Email
                                </button>
                            </div>

                            <div className="mt-8 pt-8 border-t border-white/10">
                                <Link
                                    to="/affiliate/login"
                                    className="text-gray-400 hover:text-primary-400 transition-colors"
                                >
                                    Return to Login
                                </Link>
                            </div>
                        </div>
                    </motion.div>
                </div>
            </div>
        );
    }

    if (!tokenValid || !tokenData) {
        return (
            <div className="flex items-center justify-center min-h-[60vh]">
                <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-primary-500"></div>
            </div>
        );
    }

    return (
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="max-w-md mx-auto bg-white/5 rounded-lg p-8 backdrop-blur-lg border border-white/10"
        >
            <h1 className="text-3xl font-bold text-white mb-6">Create Your Account</h1>
            <p className="text-gray-300 mb-8">
                Welcome {tokenData.first_name}! Set up your password to access your affiliate dashboard.
            </p>

            <form onSubmit={handleSubmit} className="space-y-6">
                <div>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-300">
                        Email
                    </label>
                    <input
                        type="email"
                        id="email"
                        value={tokenData.email}
                        disabled
                        className="mt-1 block w-full px-3 py-2 bg-white/5 border border-white/10 rounded-md text-gray-300 disabled:opacity-50"
                    />
                </div>

                <div>
                    <label htmlFor="password" className="block text-sm font-medium text-gray-300">
                        Password
                    </label>
                    <input
                        type="password"
                        id="password"
                        value={formData.password}
                        onChange={(e) => setFormData(prev => ({ ...prev, password: e.target.value }))}
                        required
                        className="mt-1 block w-full px-3 py-2 bg-white/5 border border-white/10 rounded-md text-gray-300 focus:ring-primary-500 focus:border-primary-500"
                    />
                </div>

                <div>
                    <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-300">
                        Confirm Password
                    </label>
                    <input
                        type="password"
                        id="confirmPassword"
                        value={formData.confirmPassword}
                        onChange={(e) => setFormData(prev => ({ ...prev, confirmPassword: e.target.value }))}
                        required
                        className="mt-1 block w-full px-3 py-2 bg-white/5 border border-white/10 rounded-md text-gray-300 focus:ring-primary-500 focus:border-primary-500"
                    />
                </div>

                <Button
                    type="submit"
                    disabled={loading}
                    variant="default"
                    size="lg"
                    className="w-full"
                >
                    {loading ? (
                        <>
                            <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-white mr-3"></div>
                            Creating Account...
                        </>
                    ) : (
                        'Create Account'
                    )}
                </Button>
            </form>
        </motion.div>
    );
}
