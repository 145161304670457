import { FC, useState } from 'react';
import { Save, RefreshCcw } from 'lucide-react';
import { toast } from 'react-hot-toast';
import { motion } from 'framer-motion';
import { aiConfig, saveConfig, resetConfig, AIProvider, ImageProvider } from '@services/config/ai';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Label } from '@/components/ui/label';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Button } from '@/components/ui/button';
import { ModelManager } from '@/admin/components/ModelManager';

interface Model {
    id: string;
    name: string;
    is_available: boolean;
    sizes?: string[];
    styles?: string[];
}

interface Settings {
    provider: AIProvider;
    imageProvider: ImageProvider;
    selectedModel: string;
    selectedImageModel: string;
    models: {
        [key in AIProvider]: Model[];
    };
    imageModels: {
        [key in ImageProvider]: Model[];
    };
    apiKey: string;
}

const AISettings: FC = () => {
    const [activeTab, setActiveTab] = useState<'text' | 'image'>('text');
    const [settings, setSettings] = useState<Settings>(() => ({
        provider: aiConfig.provider,
        imageProvider: aiConfig.imageProvider,
        selectedModel: aiConfig.selectedModel[aiConfig.provider],
        selectedImageModel: aiConfig.selectedImageModel[aiConfig.imageProvider],
        models: aiConfig.models,
        imageModels: aiConfig.imageModels,
        apiKey: ''
    }));

    const handleAddModel = (provider: AIProvider | ImageProvider) => {
        console.log('handleAddModel called with provider:', provider);
        console.log('Active tab:', activeTab);
        const isImageProvider = activeTab === 'image';
        const tempId = `new-model-${Date.now()}`;
        const newModel: Model = {
            id: tempId,
            name: 'New Model',
            is_available: true
        };

        console.log('Current settings:', settings);
        console.log('Adding new model:', newModel);
        console.log('Is image provider:', isImageProvider);

        if (isImageProvider) {
            setSettings(prev => {
                console.log('Previous imageModels:', prev.imageModels);
                return {
                    ...prev,
                    imageModels: {
                        ...prev.imageModels,
                        [provider]: [...prev.imageModels[provider as ImageProvider], newModel]
                    }
                };
            });
        } else {
            setSettings(prev => {
                console.log('Previous models:', prev.models);
                return {
                    ...prev,
                    models: {
                        ...prev.models,
                        [provider]: [...prev.models[provider as AIProvider], newModel]
                    }
                };
            });
        }
    };

    const handleRemoveModel = (provider: AIProvider | ImageProvider, modelId: string) => {
        const isImageProvider = activeTab === 'image';
        if (isImageProvider) {
            setSettings(prev => ({
                ...prev,
                imageModels: {
                    ...prev.imageModels,
                    [provider]: prev.imageModels[provider as ImageProvider].filter(
                        model => model.id !== modelId
                    )
                }
            }));
        } else {
            setSettings(prev => ({
                ...prev,
                models: {
                    ...prev.models,
                    [provider]: prev.models[provider as AIProvider].filter(
                        model => model.id !== modelId
                    )
                }
            }));
        }
    };

    const handleUpdateModel = (
        provider: AIProvider | ImageProvider,
        modelId: string,
        updates: Partial<Model>
    ) => {
        const isImageProvider = activeTab === 'image';
        if (isImageProvider) {
            setSettings(prev => ({
                ...prev,
                imageModels: {
                    ...prev.imageModels,
                    [provider]: prev.imageModels[provider as ImageProvider].map(model =>
                        model.id === modelId ? { ...model, ...updates } : model
                    )
                }
            }));
        } else {
            setSettings(prev => ({
                ...prev,
                models: {
                    ...prev.models,
                    [provider]: prev.models[provider as AIProvider].map(model =>
                        model.id === modelId ? { ...model, ...updates } : model
                    )
                }
            }));
        }
    };

    const handleSaveSettings = async () => {
        try {
            await saveConfig({
                provider: settings.provider,
                imageProvider: settings.imageProvider,
                models: settings.models,
                imageModels: settings.imageModels,
                selectedModel: {
                    ...aiConfig.selectedModel,
                    [settings.provider]: settings.selectedModel
                },
                selectedImageModel: {
                    ...aiConfig.selectedImageModel,
                    [settings.imageProvider]: settings.selectedImageModel
                }
            });
            toast.success('Settings saved successfully!');
        } catch (error) {
            console.error('Error saving settings:', error);
            toast.error('Failed to save settings');
        }
    };

    const handleResetSettings = async () => {
        try {
            await resetConfig();
            setSettings({
                provider: aiConfig.provider,
                imageProvider: aiConfig.imageProvider,
                selectedModel: aiConfig.selectedModel[aiConfig.provider],
                selectedImageModel: aiConfig.selectedImageModel[aiConfig.imageProvider],
                models: aiConfig.models,
                imageModels: aiConfig.imageModels,
                apiKey: ''
            });
            toast.success('Settings reset to default');
        } catch (error) {
            console.error('Error resetting settings:', error);
            toast.error('Failed to reset settings');
        }
    };

    return (
        <div className="container mx-auto px-4 py-8 space-y-8">
            <div className="flex justify-between items-center">
                <h1 className="text-2xl font-bold text-gray-900 dark:text-white">AI Settings</h1>
                <div className="flex items-center gap-4">
                    <motion.button
                        whileHover={{ scale: 1.02 }}
                        whileTap={{ scale: 0.98 }}
                        onClick={handleResetSettings}
                        className="flex items-center gap-2 px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors"
                    >
                        <RefreshCcw className="w-4 h-4" />
                        Reset to Default
                    </motion.button>
                    <motion.button
                        whileHover={{ scale: 1.02 }}
                        whileTap={{ scale: 0.98 }}
                        onClick={handleSaveSettings}
                        className="flex items-center gap-2 px-4 py-2 bg-primary-500 text-white rounded-lg hover:bg-primary-600 transition-colors"
                    >
                        <Save className="w-4 h-4" />
                        Save Changes
                    </motion.button>
                </div>
            </div>

            <div className="bg-white dark:bg-gray-800 shadow-xl rounded-lg overflow-hidden">
                <div className="p-6 border-b border-gray-200 dark:border-gray-700">
                    <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                        AI Configuration
                    </h2>
                </div>

                <div className="p-6">
                    <Tabs defaultValue="text" onValueChange={(value) => setActiveTab(value as 'text' | 'image')}>
                        <TabsList>
                            <TabsTrigger value="text">Text Generation</TabsTrigger>
                            <TabsTrigger value="image">Image Generation</TabsTrigger>
                        </TabsList>

                        <TabsContent value="text">
                            <Card>
                                <CardHeader>
                                    <CardTitle>Text Generation Settings</CardTitle>
                                    <CardDescription>Configure the AI provider and model for text generation</CardDescription>
                                </CardHeader>
                                <CardContent className="space-y-6">
                                    <div className="space-y-2">
                                        <Label>AI Provider</Label>
                                        <Select
                                            value={settings.provider}
                                            onValueChange={(value: AIProvider) => 
                                                setSettings(prev => ({
                                                    ...prev,
                                                    provider: value,
                                                    selectedModel: aiConfig.selectedModel[value]
                                                }))
                                            }
                                        >
                                            <SelectTrigger>
                                                <SelectValue placeholder="Select provider" />
                                            </SelectTrigger>
                                            <SelectContent>
                                                <SelectItem value="openai">OpenAI</SelectItem>
                                                <SelectItem value="deepseek">DeepSeek</SelectItem>
                                            </SelectContent>
                                        </Select>
                                    </div>

                                    <div className="space-y-2">
                                        <Label>Model</Label>
                                        <Select
                                            value={settings.selectedModel}
                                            onValueChange={(value) => 
                                                setSettings(prev => ({
                                                    ...prev,
                                                    selectedModel: value
                                                }))
                                            }
                                        >
                                            <SelectTrigger>
                                                <SelectValue placeholder="Select model" />
                                            </SelectTrigger>
                                            <SelectContent>
                                                {settings.models[settings.provider]
                                                    .filter(model => model.id)
                                                    .map(model => (
                                                        <SelectItem key={model.id} value={model.id}>
                                                            {model.name}
                                                        </SelectItem>
                                                    ))}
                                            </SelectContent>
                                        </Select>
                                    </div>

                                    <ModelManager
                                        models={settings.models[settings.provider]}
                                        onAddModel={() => {
                                            console.log('Text ModelManager: onAddModel called');
                                            handleAddModel(settings.provider);
                                        }}
                                        onRemoveModel={(id) => {
                                            console.log('Text ModelManager: onRemoveModel called with id:', id);
                                            handleRemoveModel(settings.provider, id);
                                        }}
                                        onUpdateModel={(id, updates) => {
                                            console.log('Text ModelManager: onUpdateModel called with id:', id, 'updates:', updates);
                                            handleUpdateModel(settings.provider, id, updates);
                                        }}
                                    />
                                </CardContent>
                            </Card>
                        </TabsContent>

                        <TabsContent value="image">
                            <Card>
                                <CardHeader>
                                    <CardTitle>Image Generation Settings</CardTitle>
                                    <CardDescription>Configure the AI provider and model for image generation</CardDescription>
                                </CardHeader>
                                <CardContent className="space-y-6">
                                    <div className="space-y-2">
                                        <Label>Image Provider</Label>
                                        <Select
                                            value={settings.imageProvider}
                                            onValueChange={(value: ImageProvider) => 
                                                setSettings(prev => ({
                                                    ...prev,
                                                    imageProvider: value,
                                                    selectedImageModel: aiConfig.selectedImageModel[value]
                                                }))
                                            }
                                        >
                                            <SelectTrigger>
                                                <SelectValue placeholder="Select provider" />
                                            </SelectTrigger>
                                            <SelectContent>
                                                <SelectItem value="openai">OpenAI DALL-E</SelectItem>
                                                <SelectItem value="stability">Stability AI</SelectItem>
                                            </SelectContent>
                                        </Select>
                                    </div>

                                    <div className="space-y-2">
                                        <Label>Model</Label>
                                        <Select
                                            value={settings.selectedImageModel}
                                            onValueChange={(value) => 
                                                setSettings(prev => ({
                                                    ...prev,
                                                    selectedImageModel: value
                                                }))
                                            }
                                        >
                                            <SelectTrigger>
                                                <SelectValue placeholder="Select model" />
                                            </SelectTrigger>
                                            <SelectContent>
                                                {settings.imageModels[settings.imageProvider]
                                                    .filter(model => model.id)
                                                    .map(model => (
                                                        <SelectItem key={model.id} value={model.id}>
                                                            {model.name}
                                                        </SelectItem>
                                                    ))}
                                            </SelectContent>
                                        </Select>
                                    </div>

                                    <ModelManager
                                        models={settings.imageModels[settings.imageProvider]}
                                        onAddModel={() => {
                                            console.log('Image ModelManager: onAddModel called');
                                            handleAddModel(settings.imageProvider);
                                        }}
                                        onRemoveModel={(id) => {
                                            console.log('Image ModelManager: onRemoveModel called with id:', id);
                                            handleRemoveModel(settings.imageProvider, id);
                                        }}
                                        onUpdateModel={(id, updates) => {
                                            console.log('Image ModelManager: onUpdateModel called with id:', id, 'updates:', updates);
                                            handleUpdateModel(settings.imageProvider, id, updates);
                                        }}
                                    />
                                </CardContent>
                            </Card>
                        </TabsContent>
                    </Tabs>
                </div>
            </div>
        </div>
    );
};

export default AISettings;
