import { motion } from 'framer-motion';
import { GraduationCap } from 'lucide-react';
import heroImage from '@assets/scholartree.png';

const Hero = () => {
  return (
    <div className="relative pt-32 lg:pt-24 overflow-hidden">
      {/* Background Image with Overlay */}
      <div className="absolute inset-0 z-0">
        <motion.div 
          initial={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
          animate={{ backgroundColor: "rgba(17, 24, 39, 0.95)" }}
          transition={{ duration: 1.5, delay: 0.5, ease: "easeOut" }}
          className="absolute inset-0" 
        />
        <img
          src={heroImage}
          alt="ScholarTree Background"
          className="object-cover w-full h-full"
          loading="eager"
        />
      </div>

      <div className="relative z-[1]">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="lg:grid lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24">
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5 }}
              className="mx-auto max-w-xl lg:mx-0 lg:max-w-none lg:py-16"
            >
              <div className="pl-4 lg:pl-8">
                <div className="mt-2 lg:mt-6">
                  <motion.h1
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.2 }}
                    className="text-4xl font-bold tracking-tight text-white sm:text-5xl md:text-6xl"
                  >
                    Your Personal
                    <span className="block text-primary-400">AI Learning Companion</span>
                  </motion.h1>
                  <motion.p
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.4 }}
                    className="mt-6 text-xl text-white/90"
                  >
                    Transform your learning experience with our AI-powered tutoring platform. Get personalized support, instant feedback, and comprehensive study tools - all in one place.
                  </motion.p>
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.6 }}
                    className="mt-4 lg:mt-8 flex gap-4"
                  >
                    <motion.a
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      href="#pricing"
                      className="inline-flex items-center px-6 py-3 text-base font-medium rounded-lg 
                        text-white bg-gradient-to-r from-primary-500 to-accent-500 hover:from-primary-400 
                        hover:to-accent-400 shadow-lg shadow-primary-500/25 hover:shadow-xl 
                        hover:shadow-primary-500/30 transition-all duration-200"
                    >
                      Start Learning Now
                    </motion.a>
                    <motion.a
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      href="#features"
                      className="inline-flex items-center px-6 py-3 text-base font-medium rounded-lg 
                        text-white bg-white/10 hover:bg-white/20 backdrop-blur-sm border border-white/10 
                        transition-all duration-200"
                    >
                      Explore Features
                    </motion.a>
                  </motion.div>
                </div>
              </div>
            </motion.div>
            
            <motion.div
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5, delay: 0.3 }}
              className="mt-12 sm:mt-16 lg:mt-24"
            >
              <div className="lg:relative">
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: 0.5 }}
                  className="relative overflow-hidden rounded-xl p-6 backdrop-blur-sm bg-white/5 border border-white/10 shadow-lg"
                >
                  <div className="absolute inset-0 bg-gradient-to-br from-primary-500/10 via-transparent to-accent-500/10" />
                  <div className="relative flex flex-col gap-6">
                    <div className="flex items-center gap-4">
                      <div className="flex-shrink-0">
                        <GraduationCap className="h-8 w-8 text-primary-400" />
                      </div>
                      <div>
                        <h3 className="text-lg font-semibold text-white">AI-Powered Learning</h3>
                        <p className="mt-1 text-sm text-white/80">Personalized tutoring that adapts to your needs</p>
                      </div>
                    </div>
                    <div className="space-y-4 text-sm text-white/70">
                      <p>• Expert help with any subject in real-time</p>
                      <p>• Study notes generation and other powerful tools</p>
                      <p>• Tutor personality and student learning style customization</p>
                      <p>• Voice, image and text interactions</p>
                    </div>
                  </div>
                </motion.div>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;