import React from 'react';
import ReactMarkdown, { Components } from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import remarkGfm from 'remark-gfm';
import remarkBreaks from 'remark-breaks';
import type { ComponentPropsWithoutRef } from 'react';

interface RichTextContentProps {
    content: string;
    className?: string;
}

interface CodeBlockProps extends ComponentPropsWithoutRef<'code'> {
    inline?: boolean;
}

const RichTextContent: React.FC<RichTextContentProps> = ({ content, className = '' }) => {
    // Ensure content has proper line breaks
    const processedContent = content
        .replace(/\n/g, '  \n') // Add two spaces before newlines for markdown line breaks
        .replace(/\n\n/g, '\n\n'); // Preserve paragraph breaks

    const components: Components = {
        code({ inline, className, children, ...props }: CodeBlockProps) {
            const match = /language-(\w+)/.exec(className || '');
            if (!inline && match) {
                const code = String(children).replace(/\n$/, '');
                return (
                    <SyntaxHighlighter
                        style={vscDarkPlus as any}
                        language={match[1]}
                        PreTag="div"
                    >
                        {code}
                    </SyntaxHighlighter>
                );
            }
            return (
                <code className={className} {...props}>
                    {children}
                </code>
            );
        },
        // Style links to match the theme
        a: ({ className, ...props }: ComponentPropsWithoutRef<'a'>) => (
            <a
                {...props}
                className={`text-primary-400 hover:text-primary-300 transition-colors duration-200 ${className || ''}`}
                target="_blank"
                rel="noopener noreferrer"
            />
        ),
        // Add spacing and styles for block elements
        p: ({ className, children, ...props }: ComponentPropsWithoutRef<'p'>) => (
            <p
                {...props}
                className={`mb-6 text-gray-300 leading-relaxed ${className || ''}`}
            >
                {children}
            </p>
        ),
        h1: ({ className, ...props }: ComponentPropsWithoutRef<'h1'>) => (
            <h1
                {...props}
                className={`text-3xl font-bold mb-8 text-white leading-tight ${className || ''}`}
            />
        ),
        h2: ({ className, ...props }: ComponentPropsWithoutRef<'h2'>) => (
            <h2
                {...props}
                className={`text-2xl font-bold mb-6 text-white leading-tight ${className || ''}`}
            />
        ),
        h3: ({ className, ...props }: ComponentPropsWithoutRef<'h3'>) => (
            <h3
                {...props}
                className={`text-xl font-bold mb-4 text-white leading-tight ${className || ''}`}
            />
        ),
        ul: ({ className, ...props }: ComponentPropsWithoutRef<'ul'>) => (
            <ul
                {...props}
                className={`list-disc list-inside mb-6 text-gray-300 space-y-2 ${className || ''}`}
            />
        ),
        ol: ({ className, ...props }: ComponentPropsWithoutRef<'ol'>) => (
            <ol
                {...props}
                className={`list-decimal list-inside mb-6 text-gray-300 space-y-2 ${className || ''}`}
            />
        ),
        blockquote: ({ className, ...props }: ComponentPropsWithoutRef<'blockquote'>) => (
            <blockquote
                {...props}
                className={`border-l-4 border-primary-500 pl-6 py-2 my-6 text-gray-400 italic ${className || ''}`}
            />
        ),
    };

    return (
        <div className={`rich-text-content prose prose-invert max-w-none ${className}`}>
            <ReactMarkdown 
                remarkPlugins={[remarkGfm, remarkBreaks]}
                components={components}
            >
                {processedContent}
            </ReactMarkdown>
        </div>
    );
};

export default RichTextContent;
