import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { format, parseISO, subDays } from 'date-fns';
import { 
  Download, 
  TrendingUp, 
  TrendingDown, 
  Loader2, 
  BarChart2, 
  LineChart as LucideLineChart 
} from 'lucide-react';
import { supabase } from '@/lib/supabaseClient';
import { formatCurrency } from '@/lib/utils';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

interface AffiliateWithTier {
  id: string;
  application_id: string;
  tier_id: string;
  affiliate_application: {
    first_name: string;
    last_name: string;
  };
  affiliate_tier: {
    id: string;
    name: string;
    commission_rate: number;
  };
}

interface Conversion {
  id: string;
  affiliate_id: string;
  revenue: number;
  created_at: string;
  affiliates: AffiliateWithTier;
}

interface TopAffiliate {
  id: string;
  name: string;
  revenue: number;
  conversions: number;
  conversionRate: number;
  trend: 'up' | 'down' | 'stable';
}

interface AnalyticsData {
  totalRevenue: number;
  totalConversions: number;
  totalClicks: number;
  averageConversionRate: number;
  revenueByTier: Record<string, number>;
  topAffiliates: TopAffiliate[];
  dailyStats: Array<{
    date: string;
    revenue: number;
    conversions: number;
    clicks: number;
  }>;
}

export default function AffiliateAnalytics() {
  const [dateRange, setDateRange] = useState<'7d' | '30d' | '90d' | 'custom'>('30d');
  const [startDate, setStartDate] = useState(format(subDays(new Date(), 30), 'yyyy-MM-dd'));
  const [endDate, setEndDate] = useState(format(new Date(), 'yyyy-MM-dd'));
  const [analytics, setAnalytics] = useState<AnalyticsData | null>(null);
  const [loading, setLoading] = useState(true);
  const [selectedMetric, setSelectedMetric] = useState<'revenue' | 'conversions' | 'clicks'>('revenue');

  const fetchAnalytics = async () => {
    try {
      setLoading(true);

      // Calculate date range
      const end = new Date(endDate);
      end.setHours(23, 59, 59, 999);
      const start = new Date(startDate);
      start.setHours(0, 0, 0, 0);

      // Previous period for trend analysis
      const periodLength = end.getTime() - start.getTime();
      const prevStart = new Date(start.getTime() - periodLength);

      // Get all conversions in date range with affiliate and tier info
      const { data: currentConversions } = await supabase
        .from('referral_conversions')
        .select(`
          id,
          affiliate_id,
          revenue,
          created_at,
          affiliates (
            id,
            application_id,
            tier_id,
            affiliate_applications (
              first_name,
              last_name
            ),
            affiliate_tiers (
              id,
              name,
              commission_rate
            )
          )
        `)
        .gte('created_at', start.toISOString())
        .lte('created_at', end.toISOString());

      const conversions = (currentConversions || []).map(conv => ({
        id: conv.id,
        affiliate_id: conv.affiliate_id,
        revenue: conv.revenue,
        created_at: conv.created_at,
        affiliates: {
          id: conv.affiliates?.id,
          application_id: conv.affiliates?.application_id,
          tier_id: conv.affiliates?.tier_id,
          affiliate_application: {
            first_name: conv.affiliates?.affiliate_applications?.first_name,
            last_name: conv.affiliates?.affiliate_applications?.last_name
          },
          affiliate_tier: {
            id: conv.affiliates?.affiliate_tiers?.id,
            name: conv.affiliates?.affiliate_tiers?.name,
            commission_rate: conv.affiliates?.affiliate_tiers?.commission_rate
          }
        }
      })) satisfies Conversion[];

      // Get previous period conversions for trend analysis
      const { data: previousConversions } = await supabase
        .from('referral_conversions')
        .select('affiliate_id, revenue')
        .gte('created_at', prevStart.toISOString())
        .lt('created_at', start.toISOString());

      const prevPeriodData = previousConversions || [];

      // Get clicks in date range
      const { data: clicks } = await supabase
        .from('referral_clicks')
        .select('*')
        .gte('created_at', start.toISOString())
        .lte('created_at', end.toISOString());

      // Calculate metrics
      const totalRevenue = conversions.reduce((sum, conv) => sum + (conv.revenue || 0), 0);
      const totalConversions = conversions.length;
      const totalClicks = clicks?.length || 0;
      const averageConversionRate = totalClicks ? (totalConversions / totalClicks) * 100 : 0;

      // Calculate revenue by tier
      const revenueByTier = conversions.reduce((acc, conv) => {
        const tierName = conv.affiliates?.affiliate_tier?.name || 'Unknown';
        acc[tierName] = (acc[tierName] || 0) + (Number(conv.revenue) || 0);
        return acc;
      }, {} as Record<string, number>);

      // Calculate top affiliates with trends
      const affiliateStats = conversions.reduce((acc, conv) => {
        const affId = conv.affiliate_id;
        if (!acc[affId]) {
          acc[affId] = {
            id: affId,
            name: conv.affiliates?.affiliate_application?.first_name && conv.affiliates?.affiliate_application?.last_name
              ? `${conv.affiliates.affiliate_application.first_name} ${conv.affiliates.affiliate_application.last_name}`
              : 'Unknown',
            revenue: 0,
            conversions: 0,
            clicks: 0,
            prevRevenue: 0
          };
        }
        acc[affId].revenue += Number(conv.revenue) || 0;
        acc[affId].conversions += 1;
        return acc;
      }, {} as Record<string, {
        id: string;
        name: string;
        revenue: number;
        conversions: number;
        clicks: number;
        prevRevenue: number;
      }>);

      // Add previous period revenue for trend calculation
      prevPeriodData.forEach(conv => {
        const affId = conv.affiliate_id;
        if (affiliateStats[affId]) {
          affiliateStats[affId].prevRevenue += Number(conv.revenue) || 0;
        }
      });

      // Add click counts and calculate trends
      clicks?.forEach(click => {
        if (affiliateStats[click.affiliate_id]) {
          affiliateStats[click.affiliate_id].clicks += 1;
        }
      });

      const topAffiliates: TopAffiliate[] = Object.values(affiliateStats)
        .map(aff => ({
          id: aff.id,
          name: aff.name,
          revenue: aff.revenue,
          conversions: aff.conversions,
          conversionRate: aff.clicks ? (aff.conversions / aff.clicks) * 100 : 0,
          trend: aff.revenue > aff.prevRevenue ? 'up' as const : 
                 aff.revenue < aff.prevRevenue ? 'down' as const : 
                 'stable' as const
        }))
        .sort((a, b) => b.revenue - a.revenue)
        .slice(0, 10);

      // Calculate daily stats
      const dailyStats = conversions.reduce((acc, conv) => {
        const date = format(parseISO(conv.created_at), 'yyyy-MM-dd');
        if (!acc[date]) {
          acc[date] = {
            date,
            revenue: 0,
            conversions: 0,
            clicks: 0
          };
        }
        acc[date].revenue += Number(conv.revenue) || 0;
        acc[date].conversions += 1;
        return acc;
      }, {} as Record<string, any>);

      // Add click data to daily stats
      clicks?.forEach(click => {
        const date = format(parseISO(click.created_at), 'yyyy-MM-dd');
        if (dailyStats[date]) {
          dailyStats[date].clicks += 1;
        }
      });

      setAnalytics({
        totalRevenue,
        totalConversions,
        totalClicks,
        averageConversionRate,
        revenueByTier,
        topAffiliates,
        dailyStats: Object.values(dailyStats).sort((a, b) => 
          new Date(a.date).getTime() - new Date(b.date).getTime()
        )
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const calculateDateRange = () => {
      switch (dateRange) {
        case '7d':
          setStartDate(format(subDays(new Date(), 7), 'yyyy-MM-dd'));
          setEndDate(format(new Date(), 'yyyy-MM-dd'));
          break;
        case '30d':
          setStartDate(format(subDays(new Date(), 30), 'yyyy-MM-dd'));
          setEndDate(format(new Date(), 'yyyy-MM-dd'));
          break;
        case '90d':
          setStartDate(format(subDays(new Date(), 90), 'yyyy-MM-dd'));
          setEndDate(format(new Date(), 'yyyy-MM-dd'));
          break;
      }
    };

    calculateDateRange();
    fetchAnalytics();
  }, [dateRange]);

  const handleStartDateChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setStartDate(e.target.value);
    setDateRange('custom');
  };

  const handleEndDateChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setEndDate(e.target.value);
    setDateRange('custom');
  };

  const handleExport = () => {
    if (!analytics) return;

    const csvData = [
      ['Summary'],
      ['Metric', 'Value'],
      ['Total Revenue', analytics.totalRevenue],
      ['Total Conversions', analytics.totalConversions],
      ['Total Clicks', analytics.totalClicks],
      ['Average Conversion Rate', `${analytics.averageConversionRate.toFixed(2)}%`],
      [''],
      
      // Rest of the export logic remains the same
    ].map(row => row.join(',')).join('\n');

    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `affiliate-analytics-${format(new Date(), 'yyyy-MM-dd')}.csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="flex flex-col items-center space-y-4">
          <Loader2 className="w-12 h-12 animate-spin text-primary" />
          <p className="text-gray-500 dark:text-gray-400">Loading analytics data...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8 space-y-8">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold text-gray-900 dark:text-white">Affiliate Analytics</h1>
        <div className="flex items-center gap-4">
          <div className="flex items-center space-x-2">
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => setDateRange('7d')}
              className={`px-3 py-1 rounded-full text-sm transition-colors ${
                dateRange === '7d' 
                  ? 'bg-primary-500 text-white' 
                  : 'bg-gray-100 dark:bg-gray-700 text-gray-600 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-600'
              }`}
            >
              7D
            </motion.button>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => setDateRange('30d')}
              className={`px-3 py-1 rounded-full text-sm transition-colors ${
                dateRange === '30d' 
                  ? 'bg-primary-500 text-white' 
                  : 'bg-gray-100 dark:bg-gray-700 text-gray-600 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-600'
              }`}
            >
              30D
            </motion.button>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => setDateRange('90d')}
              className={`px-3 py-1 rounded-full text-sm transition-colors ${
                dateRange === '90d' 
                  ? 'bg-primary-500 text-white' 
                  : 'bg-gray-100 dark:bg-gray-700 text-gray-600 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-600'
              }`}
            >
              90D
            </motion.button>
          </div>
          
          {dateRange === 'custom' && (
            <div className="flex items-center space-x-2">
              <input
                type="date"
                value={startDate}
                onChange={handleStartDateChange}
                className="px-2 py-1 border rounded-md dark:bg-gray-800 dark:border-gray-700"
              />
              <span className="text-gray-500">to</span>
              <input
                type="date"
                value={endDate}
                onChange={handleEndDateChange}
                className="px-2 py-1 border rounded-md dark:bg-gray-800 dark:border-gray-700"
              />
            </div>
          )}

          <motion.button
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            onClick={handleExport}
            className="flex items-center gap-2 px-4 py-2 bg-primary-500 text-white rounded-lg hover:bg-primary-600 transition-colors"
          >
            <Download className="w-4 h-4" />
            Export Report
          </motion.button>
        </div>
      </div>

      {analytics && (
        <div className="space-y-8">
          {/* Summary Cards */}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
            {[
              {
                title: 'Total Revenue',
                value: formatCurrency(analytics.totalRevenue),
                subtitle: dateRange === '7d' ? 'Past 7 days' : dateRange === '30d' ? 'Past 30 days' : dateRange === '90d' ? 'Past 90 days' : 'Custom range'
              },
              {
                title: 'Total Conversions',
                value: analytics.totalConversions.toLocaleString(),
                subtitle: analytics.totalConversions > 0 
                  ? `${formatCurrency(analytics.totalRevenue / analytics.totalConversions)} avg. value` 
                  : 'No conversions'
              },
              {
                title: 'Total Clicks',
                value: analytics.totalClicks.toLocaleString(),
                subtitle: analytics.totalClicks > 0 
                  ? `${(analytics.totalConversions / analytics.totalClicks * 100).toFixed(1)}% conversion rate` 
                  : 'No clicks'
              },
              {
                title: 'Conversion Rate',
                value: `${analytics.averageConversionRate.toFixed(1)}%`,
                subtitle: `${analytics.totalClicks} total clicks`
              }
            ].map((card, index) => (
              <motion.div 
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                className="bg-white dark:bg-gray-800 shadow-lg rounded-lg p-6 hover:shadow-xl transition-all"
              >
                <div className="flex flex-col">
                  <span className="text-sm font-medium text-gray-500 dark:text-gray-400">{card.title}</span>
                  <span className="text-3xl font-bold mt-2 text-gray-900 dark:text-white">{card.value}</span>
                  <span className="text-sm text-gray-500 dark:text-gray-400 mt-1">{card.subtitle}</span>
                </div>
              </motion.div>
            ))}
          </div>

          {/* Charts */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            <motion.div 
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.2 }}
              className="bg-white dark:bg-gray-800 shadow-lg rounded-lg p-6"
            >
              <div className="flex justify-between items-center mb-6">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">Performance Over Time</h3>
                <div className="flex items-center space-x-2">
                  {['revenue', 'conversions', 'clicks'].map((metric) => (
                    <motion.button
                      key={metric}
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      onClick={() => setSelectedMetric(metric as any)}
                      className={`px-3 py-1 rounded-full text-sm transition-colors ${
                        selectedMetric === metric 
                          ? 'bg-primary-500 text-white' 
                          : 'bg-gray-100 dark:bg-gray-700 text-gray-600 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-600'
                      }`}
                    >
                      {metric.charAt(0).toUpperCase() + metric.slice(1)}
                    </motion.button>
                  ))}
                </div>
              </div>
              <div className="h-[300px]">
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart data={analytics.dailyStats}>
                    <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
                    <XAxis
                      dataKey="date"
                      tickFormatter={(date) => format(parseISO(date), 'MMM d')}
                      className="text-gray-600 dark:text-gray-400"
                    />
                    <YAxis 
                      className="text-gray-600 dark:text-gray-400"
                      tickFormatter={(value) => 
                        selectedMetric === 'revenue' 
                          ? formatCurrency(value) 
                          : value.toLocaleString()
                      } 
                    />
                    <Tooltip 
                      formatter={(value, name) => [
                        selectedMetric === 'revenue' ? formatCurrency(Number(value)) : value,
                        name
                      ]}
                      labelFormatter={(label) => format(parseISO(label), 'MMMM d, yyyy')}
                      contentStyle={{ 
                        backgroundColor: 'rgba(17, 24, 39, 0.9)', 
                        color: 'white', 
                        borderRadius: '0.5rem' 
                      }}
                    />
                    <Line 
                      type="monotone" 
                      dataKey={selectedMetric} 
                      stroke="#3B82F6" 
                      strokeWidth={3} 
                      dot={{ r: 5 }} 
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </motion.div>

            {/* Top Affiliates Section */}
            <motion.div 
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.3 }}
              className="bg-white dark:bg-gray-800 shadow-lg rounded-lg p-6"
            >
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-6">Top Affiliates</h3>
              <div className="space-y-4">
                {analytics.topAffiliates.map((affiliate, index) => (
                  <motion.div
                    key={affiliate.id}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: index * 0.1 }}
                    className="flex items-center justify-between bg-gray-50 dark:bg-gray-700 p-4 rounded-lg"
                  >
                    <div>
                      <span className="font-medium text-gray-900 dark:text-white">{affiliate.name}</span>
                      <div className="text-sm text-gray-500 dark:text-gray-400">
                        {formatCurrency(affiliate.revenue)} | {affiliate.conversions} conversions
                      </div>
                    </div>
                    <div className="flex items-center space-x-2">
                      <span className="text-sm text-gray-500 dark:text-gray-400">
                        {(affiliate.conversionRate * 100).toFixed(1)}%
                      </span>
                      {affiliate.trend === 'up' ? (
                        <TrendingUp className="w-5 h-5 text-green-500" />
                      ) : affiliate.trend === 'down' ? (
                        <TrendingDown className="w-5 h-5 text-red-500" />
                      ) : null}
                    </div>
                  </motion.div>
                ))}
              </div>
            </motion.div>
          </div>
        </div>
      )}
    </div>
  );
}
