import { useState } from 'react';
import { motion } from 'framer-motion';
import { format } from 'date-fns';
import { 
  Download, 
  Loader2, 
  UserCheck, 
  UserX, 
  X 
} from 'lucide-react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';
import { affiliateApi } from '@/services/api/affiliate';
import { paymentApi } from '@/services/api/payments';
import { formatCurrency } from '@/lib/utils';
import { supabase } from '@/lib/supabaseClient';
import type { PayoutRequest, AffiliateDetails, PaymentHistory } from '@/types/affiliate';

const PayoutManagement = () => {
  const queryClient = useQueryClient();
  const [processingId, setProcessingId] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState<'pending' | 'scheduled' | 'history'>('pending');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedPayout, setSelectedPayout] = useState<PayoutRequest | PaymentHistory | null>(null);

  // Fetch payout requests
  const { data: payoutRequests, isLoading } = useQuery<PayoutRequest[]>({
    queryKey: ['payout-requests'],
    queryFn: affiliateApi.getPayoutRequests,
  });

  // Fetch payment history
  const { data: paymentHistory } = useQuery<PaymentHistory[]>({
    queryKey: ['payment-history'],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('payout_requests')
        .select('*')
        .eq('status', 'completed')
        .order('processed_at', { ascending: false });

      if (error) {
        console.error('Error fetching payment history:', error);
        return [];
      }

      return data || [];
    },
    enabled: activeTab === 'history'
  });

  // Fetch affiliate details for each unique affiliate
  const affiliateIds = [...new Set([
    ...(payoutRequests?.map(req => req.affiliate_id) || []),
    ...(paymentHistory?.map(payment => payment.affiliate_id) || [])
  ])];

  const { data: affiliateDetails } = useQuery<Record<string, AffiliateDetails>>({
    queryKey: ['affiliate-details', affiliateIds],
    queryFn: async () => {
      if (!affiliateIds.length) return {};

      const { data } = await supabase
        .from('affiliates')
        .select(`
          id,
          user_id,
          affiliate_applications (
            first_name,
            last_name,
            email
          )
        `)
        .in('id', affiliateIds);

      return (data || []).reduce<Record<string, AffiliateDetails>>((acc, affiliate: any) => {
        if (affiliate.affiliate_applications?.[0]) {
          acc[affiliate.id] = {
            id: affiliate.id,
            user_id: affiliate.user_id,
            first_name: affiliate.affiliate_applications[0].first_name,
            last_name: affiliate.affiliate_applications[0].last_name,
            email: affiliate.affiliate_applications[0].email,
          };
        }
        return acc;
      }, {});
    },
    enabled: !!affiliateIds.length,
  });

  const batchProcessMutation = useMutation({
    mutationFn: async (ids: string[]) => {
      const results = await Promise.allSettled(
        ids.map(id => paymentApi.processPayout(id))
      );
      
      const succeeded = results.filter(r => r.status === 'fulfilled').length;
      const failed = results.filter(r => r.status === 'rejected').length;
      
      return { succeeded, failed };
    },
    onSuccess: (result) => {
      toast.success(`Processed ${result.succeeded} payouts successfully${result.failed ? `. ${result.failed} failed` : ''}`);
      queryClient.invalidateQueries({ queryKey: ['payout-requests'] });
      queryClient.invalidateQueries({ queryKey: ['payment-history'] });
    },
    onError: (error) => {
      console.error('Error processing batch payouts:', error);
      toast.error('Failed to process batch payouts');
    },
  });

  const handleExport = (data: any[]) => {
    const csvContent = [
      ['Affiliate', 'Email', 'Amount', 'Status', 'Date'].join(','),
      ...data.map(item => [
        affiliateDetails?.[item.affiliate_id]?.first_name || 'Unknown',
        affiliateDetails?.[item.affiliate_id]?.last_name || 'Unknown',
        affiliateDetails?.[item.affiliate_id]?.email || 'Unknown',
        item.amount,
        item.status,
        format(new Date(item.created_at), 'yyyy-MM-dd HH:mm:ss')
      ].join(','))
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `payout-${activeTab}-${format(new Date(), 'yyyy-MM-dd')}.csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  const handleViewDetails = (payout: PayoutRequest | PaymentHistory) => {
    setSelectedPayout(payout);
  };

  const closeDetailsDialog = () => {
    setSelectedPayout(null);
  };

  const filteredRequests = payoutRequests?.filter(req => {
    const affiliate = affiliateDetails?.[req.affiliate_id];
    return !searchTerm || 
      affiliate?.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      affiliate?.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      affiliate?.email.toLowerCase().includes(searchTerm.toLowerCase());
  }) || [];

  const pendingRequests = filteredRequests.filter(req => req.status === 'pending');
  const scheduledRequests = filteredRequests.filter(req => req.status === 'scheduled');

  const filteredHistory = paymentHistory?.filter(payment => {
    const affiliate = affiliateDetails?.[payment.affiliate_id];
    return !searchTerm || 
      affiliate?.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      affiliate?.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      affiliate?.email.toLowerCase().includes(searchTerm.toLowerCase());
  }) || [];

  const getPayoutDate = (payout: PayoutRequest | PaymentHistory) => {
    const dateField = 'created_at' in payout 
      ? payout.created_at 
      : 'processed_at' in payout 
        ? payout.processed_at 
        : new Date().toISOString();
    return new Date(dateField);
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <Loader2 className="h-8 w-8 animate-spin text-gray-400" />
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8 space-y-8">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold text-gray-900 dark:text-white">Payout Management</h1>
        <div className="flex items-center gap-4">
          <div className="flex items-center space-x-2">
            {['pending', 'scheduled', 'history'].map((tab) => (
              <motion.button
                key={tab}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => setActiveTab(tab as any)}
                className={`px-3 py-1 rounded-full text-sm capitalize transition-colors ${
                  activeTab === tab 
                    ? 'bg-primary-500 text-white' 
                    : 'bg-gray-100 dark:bg-gray-700 text-gray-600 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-600'
                }`}
              >
                {tab}
              </motion.button>
            ))}
          </div>

          <div className="relative">
            <input
              type="text"
              placeholder="Search affiliates..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="pl-8 pr-4 py-2 border rounded-lg dark:bg-gray-800 dark:border-gray-700 dark:text-white"
            />
            <Download className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400" />
          </div>

          <motion.button
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            onClick={() => handleExport(activeTab === 'pending' ? pendingRequests : activeTab === 'scheduled' ? scheduledRequests : filteredHistory)}
            className="flex items-center gap-2 px-4 py-2 bg-primary-500 text-white rounded-lg hover:bg-primary-600 transition-colors"
          >
            <Download className="w-4 h-4" />
            Export
          </motion.button>
        </div>
      </div>

      <div className="bg-white dark:bg-gray-800 shadow-xl rounded-lg overflow-hidden">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
            <thead className="bg-gray-50 dark:bg-gray-900">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Affiliate
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Amount
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Date
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
              {(activeTab === 'pending' ? pendingRequests : activeTab === 'scheduled' ? scheduledRequests : filteredHistory).map((payout) => (
                <tr key={payout.id}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-white">
                    {affiliateDetails?.[payout.affiliate_id]?.first_name} {affiliateDetails?.[payout.affiliate_id]?.last_name}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                    {formatCurrency(payout.amount)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                    {format(getPayoutDate(payout), 'MMM d, yyyy')}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    <div className="flex justify-end gap-2">
                      <motion.button
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                        onClick={() => handleViewDetails(payout)}
                        className="text-blue-600 hover:text-blue-900 dark:text-blue-400 dark:hover:text-blue-300"
                        title="View Details"
                      >
                        <UserCheck className="w-4 h-4" />
                      </motion.button>
                      {activeTab === 'pending' && (
                        <motion.button
                          whileHover={{ scale: 1.1 }}
                          whileTap={{ scale: 0.9 }}
                          onClick={() => {
                            setProcessingId(payout.id);
                            batchProcessMutation.mutate([payout.id]);
                          }}
                          disabled={processingId === payout.id}
                          className="text-green-600 hover:text-green-900 dark:text-green-400 dark:hover:text-green-300"
                          title="Process"
                        >
                          <UserX className="w-4 h-4" />
                        </motion.button>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Payout Details Modal */}
      {selectedPayout && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
          onClick={closeDetailsDialog}
        >
          <motion.div
            initial={{ scale: 0.9 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0.9 }}
            className="bg-white dark:bg-gray-800 rounded-xl shadow-2xl max-w-2xl w-full mx-4"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="p-6 border-b border-gray-200 dark:border-gray-700 flex justify-between items-center">
              <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                Payout Details
              </h2>
              <motion.button
                whileHover={{ rotate: 90 }}
                onClick={closeDetailsDialog}
                className="text-gray-500 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
              >
                <X className="w-6 h-6" />
              </motion.button>
            </div>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 p-6">
              <div>
                <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
                  Affiliate Information
                </h3>
                <div className="space-y-3">
                  <div>
                    <label className="text-sm text-gray-500 dark:text-gray-400">Name</label>
                    <p className="text-gray-900 dark:text-white">
                      {affiliateDetails?.[selectedPayout.affiliate_id]?.first_name} {affiliateDetails?.[selectedPayout.affiliate_id]?.last_name}
                    </p>
                  </div>
                  <div>
                    <label className="text-sm text-gray-500 dark:text-gray-400">Email</label>
                    <p className="text-gray-900 dark:text-white">
                      {affiliateDetails?.[selectedPayout.affiliate_id]?.email}
                    </p>
                  </div>
                </div>
              </div>
              
              <div>
                <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
                  Payout Details
                </h3>
                <div className="space-y-3">
                  <div>
                    <label className="text-sm text-gray-500 dark:text-gray-400">Amount</label>
                    <p className="text-gray-900 dark:text-white">
                      {formatCurrency(selectedPayout.amount)}
                    </p>
                  </div>
                  <div>
                    <label className="text-sm text-gray-500 dark:text-gray-400">Date</label>
                    <p className="text-gray-900 dark:text-white">
                      {format(getPayoutDate(selectedPayout), 'MMMM d, yyyy HH:mm:ss')}
                    </p>
                  </div>
                  <div>
                    <label className="text-sm text-gray-500 dark:text-gray-400">Status</label>
                    <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                      selectedPayout.status === 'pending'
                        ? 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-200'
                        : selectedPayout.status === 'completed'
                        ? 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200'
                        : 'bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-200'
                    }`}>
                      {selectedPayout.status}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </div>
  );
};

export default PayoutManagement;
