import { type RouteObject, Navigate, Outlet } from 'react-router-dom';
import AdminLayout from '@admin/layouts/AdminLayout';
import Dashboard from '@admin/pages/Dashboard';
import Login from '@admin/pages/Login';
import ResetPassword from '@admin/pages/ResetPassword';
import UpdatePassword from '@admin/pages/UpdatePassword';
import Newsletter from '@admin/pages/Newsletter';
import AISettings from '@admin/pages/AISettings';
import Analytics from '@admin/pages/Analytics';
import Blog from '@admin/pages/Blog';
import BlogEditor from '@admin/pages/BlogEditor';
import EmailTemplates from '@admin/pages/EmailTemplates';
import EmailTemplateEditor from '@admin/pages/EmailTemplateEditor';
import AffiliateManagement from './pages/AffiliateManagement';
import PayoutManagement from './pages/PayoutManagement';
import AffiliateAnalytics from './pages/AffiliateAnalytics';
import AdminStorage from './pages/AdminStorage'; // New import
import RoleProtectedRoute from '@/components/auth/RoleProtectedRoute';

const adminRoutes: RouteObject[] = [
    {
        path: "login",
        element: <Login />
    },
    {
        path: "reset-password",
        element: <ResetPassword />
    },
    {
        path: "update-password",
        element: <UpdatePassword />
    },
    {
        element: (
            <RoleProtectedRoute requiredRoles="admin">
                <AdminLayout>
                    <Outlet />
                </AdminLayout>
            </RoleProtectedRoute>
        ),
        children: [
            {
                path: "",
                element: <Dashboard />
            },
            {
                path: "blog",
                element: <Blog />
            },
            {
                path: "blog/new",
                element: <BlogEditor />
            },
            {
                path: "blog/edit/:id",
                element: <BlogEditor />
            },
            {
                path: "newsletter",
                element: <Newsletter />
            },
            {
                path: "email-templates",
                element: <EmailTemplates />
            },
            {
                path: "email-templates/new",
                element: <EmailTemplateEditor />
            },
            {
                path: "email-templates/:id",
                element: <EmailTemplateEditor />
            },
            {
                path: "ai-settings",
                element: <AISettings />
            },
            {
                path: "analytics",
                element: <Analytics />
            },
            {
                path: "affiliate",
                children: [
                    {
                        path: "management",
                        element: <AffiliateManagement />
                    },
                    {
                        path: "payouts",
                        element: <PayoutManagement />
                    },
                    {
                        path: "analytics",
                        element: <AffiliateAnalytics />
                    }
                ]
            },
            {
                path: "storage",
                element: <AdminStorage />
            }
        ]
    },
    {
        path: "*",
        element: <Navigate to="/admin/login" replace />
    }
];

export default adminRoutes;
