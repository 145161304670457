import { motion, useScroll, useTransform } from 'framer-motion';
import { Brain, BookOpen, Bell, Users, Sparkles, Mic } from 'lucide-react';

const features = [
  {
    name: 'Virtual AI Tutor',
    description: 'Get personalized tutoring with our advanced AI that adapts to your learning style and pace. Support for text, voice, and image interactions.',
    icon: Brain,
    color: 'from-primary-500 to-accent-500'
  },
  {
    name: 'Smart Study Tools',
    description: 'Generate study materials instantly including flashcards, mind maps, and practice questions. Features equation drawing with step-by-step solutions.',
    icon: BookOpen,
    color: 'from-primary-400 to-primary-600'
  },
  {
    name: 'Activity Tracking',
    description: 'Comprehensive progress monitoring with detailed analytics, session histories, and performance insights.',
    icon: Sparkles,
    color: 'from-accent-400 to-accent-600'
  },
  {
    name: 'Parental Controls',
    description: 'Advanced content filtering and monitoring with customizable alerts and comprehensive activity tracking.',
    icon: Bell,
    color: 'from-primary-500 to-primary-700'
  },
  {
    name: 'Voice Interaction',
    description: 'Natural voice conversations with audio recording and transcription for an immersive learning experience.',
    icon: Mic,
    color: 'from-accent-500 to-accent-700'
  },
  {
    name: 'Multi-User Support',
    description: 'Family accounts with multiple student profiles, cross-device syncing, and shared resource access.',
    icon: Users,
    color: 'from-primary-600 to-accent-600'
  }
];

const FeatureCard = ({ feature, index }: { feature: typeof features[0], index: number }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      whileHover={{ 
        y: -8,
        scale: 1.02,
        transition: { 
          type: "spring",
          stiffness: 400,
          damping: 10
        }
      }}
      viewport={{ once: true, margin: "-100px" }}
      transition={{ 
        duration: 0.5,
        delay: index * 0.1,
        ease: [0.21, 1.11, 0.81, 0.99]
      }}
      className="relative overflow-hidden rounded-xl p-6 h-full backdrop-blur-sm bg-white/5 border border-white/10 shadow-lg"
    >
      <div className={`absolute top-0 left-0 w-full h-1 bg-gradient-to-r ${feature.color}`} />
      <div className="relative">
        <div className={`flex h-12 w-12 items-center justify-center rounded-lg bg-gradient-to-br ${feature.color}`}>
          <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
        </div>
        <div className="mt-6">
          <h3 className="text-lg font-medium leading-6 text-white">{feature.name}</h3>
          <p className="mt-2 text-base text-white/80">{feature.description}</p>
        </div>
      </div>
    </motion.div>
  );
};

const Features = () => {
  const { scrollYProgress } = useScroll();
  const y = useTransform(scrollYProgress, [0, 1], [0, -50]);

  return (
    <div id="features" className="relative py-24 overflow-hidden">
      <motion.div
        style={{ y }}
        className="absolute inset-0 bg-gradient-radial from-primary-500/20 via-transparent to-transparent opacity-50"
      />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8, ease: [0.21, 1.11, 0.81, 0.99] }}
          className="lg:text-center"
        >
          <motion.h2
            initial={{ opacity: 0, y: -20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
            className="text-base text-primary-400 font-semibold tracking-wide uppercase"
          >
            Features
          </motion.h2>
          <motion.p
            initial={{ opacity: 0, scale: 0.95 }}
            whileInView={{ opacity: 1, scale: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="mt-2 text-3xl leading-8 font-bold tracking-tight bg-gradient-to-r from-primary-400 to-accent-400 bg-clip-text text-transparent sm:text-4xl"
          >
            Everything you need to excel
          </motion.p>
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.4 }}
            className="mt-4 max-w-2xl text-xl text-primary-200 lg:mx-auto"
          >
            Comprehensive learning tools designed to support your educational journey.
          </motion.p>
        </motion.div>

        <div className="mt-20">
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
            {features.map((feature, index) => (
              <FeatureCard key={feature.name} feature={feature} index={index} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;