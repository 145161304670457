import { useState } from 'react';
import { useQuery, useMutation } from '@tanstack/react-query';
import { useAuth } from '@/hooks/useAuth';
import { useToast } from '@/components/ui/use-toast';
import { supabase } from '@/lib/supabaseClient';
import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

export default function AffiliateCode() {
  const { user } = useAuth();
  const { toast } = useToast();
  const [copied, setCopied] = useState(false);
  const [newReferralCode, setNewReferralCode] = useState('');

  // Fetch affiliate data
  const { data: affiliate, refetch } = useQuery({
    queryKey: ['affiliate', user?.id],
    queryFn: async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) throw new Error('No active session');

      const { data, error } = await supabase
        .from('affiliates')
        .select('referral_code')
        .eq('user_id', session.user.id)
        .single();

      if (error) throw error;
      return data;
    },
    enabled: !!user,
  });

  // Mutation to update referral code
  const updateReferralCodeMutation = useMutation({
    mutationFn: async (newCode: string) => {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) throw new Error('No active session');

      // Validate referral code
      if (!isValidReferralCode(newCode)) {
        throw new Error('Invalid referral code');
      }

      try {
        // Explicitly update only referral_code
        const { data, error } = await supabase
          .from('affiliates')
          .update({ referral_code: newCode })
          .eq('user_id', session.user.id)
          .select()
          .single();

        console.log('Supabase update payload:', { referral_code: newCode });
        console.log('Supabase update response:', { data, error });

        if (error) {
          console.error('Detailed update error:', JSON.stringify(error, null, 2));
          throw error;
        }

        return data;
      } catch (catchError) {
        console.error('Update catch block error:', catchError);
        throw catchError;
      }
    },
    onSuccess: () => {
      toast({
        title: 'Success',
        description: 'Referral code updated successfully',
      });
      refetch(); // Refresh the affiliate data
      setNewReferralCode(''); // Clear input
    },
    onError: (error) => {
      console.error('Mutation error:', error);
      toast({
        title: 'Error',
        description: error instanceof Error 
          ? error.message 
          : 'Failed to update referral code',
        variant: 'destructive',
      });
    }
  });

  // Validate referral code
  const isValidReferralCode = (code: string): boolean => {
    // Check length
    if (code.length < 3 || code.length > 20) {
      toast({
        title: 'Invalid Code',
        description: 'Referral code must be between 3 and 20 characters',
        variant: 'destructive',
      });
      return false;
    }

    // Check for inappropriate content (basic check)
    const inappropriatePatterns = [
      /fuck/i, 
      /shit/i, 
      /bitch/i, 
      /nigger/i, 
      /cunt/i
    ];

    if (inappropriatePatterns.some(pattern => pattern.test(code))) {
      toast({
        title: 'Inappropriate Content',
        description: 'Referral code contains inappropriate language',
        variant: 'destructive',
      });
      return false;
    }

    // Alphanumeric check
    if (!/^[a-zA-Z0-9]+$/.test(code)) {
      toast({
        title: 'Invalid Characters',
        description: 'Referral code can only contain letters and numbers',
        variant: 'destructive',
      });
      return false;
    }

    return true;
  };

  // Generate referral links
  const websiteReferralLink = `https://scholartree.app?ref=${affiliate?.referral_code}`;
  const iOSReferralCode = affiliate?.referral_code || '';

  const copyToClipboard = async (text: string, type: 'website' | 'ios') => {
    try {
      await navigator.clipboard.writeText(text);
      setCopied(true);
      toast({
        title: 'Success',
        description: `${type === 'website' ? 'Website' : 'iOS'} referral ${type === 'website' ? 'link' : 'code'} copied to clipboard!`,
      });
      setTimeout(() => setCopied(false), 2000);
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to copy link',
        variant: 'destructive',
      });
    }
  };

  return (
    <div className="container mx-auto py-8 px-4">
      <h1 className="text-3xl font-bold text-white mb-8">Your Referral Codes</h1>
      
      <div className="grid gap-6">
        {/* Website Referral Link */}
        <Card className="p-6 bg-gray-900/50 border-gray-800">
          <h2 className="text-lg font-semibold text-white mb-2">Website Referral Link</h2>
          <p className="text-gray-400 mb-6">
            Share this link with your audience to start earning commissions.
          </p>
          
          <div className="flex gap-4 items-center">
            <code className="flex-1 bg-gray-800/50 text-white px-4 py-2.5 rounded-lg border border-gray-700">
              {websiteReferralLink}
            </code>
            <Button
              onClick={() => copyToClipboard(websiteReferralLink, 'website')}
              className="min-w-[100px]"
              variant={copied ? "secondary" : "default"}
            >
              {copied ? 'Copied!' : 'Copy'}
            </Button>
          </div>
        </Card>

        {/* iOS App Referral Code */}
        <Card className="p-6 bg-gray-900/50 border-gray-800">
          <h2 className="text-lg font-semibold text-white mb-2">iOS App Referral Code</h2>
          <p className="text-gray-400 mb-6">
            Use this code for iOS app referrals.
          </p>
          
          <div className="flex gap-4 items-center">
            <code className="flex-1 bg-gray-800/50 text-white px-4 py-2.5 rounded-lg border border-gray-700">
              {iOSReferralCode}
            </code>
            <Button
              onClick={() => copyToClipboard(iOSReferralCode, 'ios')}
              className="min-w-[100px]"
              variant={copied ? "secondary" : "default"}
            >
              {copied ? 'Copied!' : 'Copy'}
            </Button>
          </div>
        </Card>

        {/* Change Referral Code */}
        <Card className="p-6 bg-gray-900/50 border-gray-800">
          <h2 className="text-lg font-semibold text-white mb-4">Change your Referral Code</h2>
          
          <div className="space-y-4">
            <div>
              <Label htmlFor="referralCode" className="text-gray-400">New Referral Code</Label>
              <Input
                type="text"
                placeholder="Enter your new referral code"
                value={newReferralCode}
                onChange={(e) => setNewReferralCode(e.target.value)}
                className="bg-gray-800 border-gray-700 text-white focus:ring-blue-500 focus:border-blue-500"
              />
            </div>

            {/* Code of Conduct Warning */}
            <div className="bg-yellow-500/10 border border-yellow-500/50 rounded-lg p-4 text-gray-400 text-sm">
              <div className="flex items-start">
                <ExclamationTriangleIcon className="h-5 w-5 text-yellow-500 mr-2 mt-0.5" aria-hidden="true" />
                <div>
                  <strong>Code of Conduct Warning:</strong> Referral codes containing inappropriate language, 
                  offensive content, or violating our community standards will result in immediate affiliate 
                  account termination without warning.
                </div>
              </div>
            </div>

            <Button 
              type="submit"
              variant="default"
              onClick={() => updateReferralCodeMutation.mutate(newReferralCode)}
              className="self-start w-auto bg-blue-600 hover:bg-blue-700 text-white font-semibold py-1.5 px-3 rounded-lg transition-colors duration-300"
              disabled={!isValidReferralCode(newReferralCode) || updateReferralCodeMutation.status === 'pending'}
            >
              {updateReferralCodeMutation.status === 'pending' ? 'Updating...' : 'Update Referral Code'}
            </Button>
          </div>
        </Card>

        <Card className="p-6 bg-gray-900/50 border-gray-800">
          <h2 className="text-lg font-semibold text-white mb-2">Tips for Sharing</h2>
          <ul className="space-y-3 text-gray-400">
            <li>• Add your referral link to your social media profiles</li>
            <li>• Include it in your email signatures</li>
            <li>• Share it in relevant blog posts or content</li>
            <li>• Use it in your social media posts about AI</li>
          </ul>
        </Card>
      </div>
    </div>
  );
}
