import { type FC } from 'react';
import { useQuery } from '@tanstack/react-query';
import { motion } from 'framer-motion';
import { ArrowUpIcon, ArrowDownIcon } from '@heroicons/react/24/solid';
import { formatCurrency, formatPercentage } from '@/utils/format';
import { getAffiliateDashboardData } from '@/services/api/affiliateDashboard';
import { AffiliateDashboardData } from '@/types/affiliate';
import LoadingSpinner from '@/components/common/LoadingSpinner';

interface Props {
    affiliateId: string;
    tierId: string;
}

const MetricCard: FC<{
    title: string;
    value: string | number;
    previousValue: string | number;
    isPercentage?: boolean;
    isCurrency?: boolean;
}> = ({ title, value, previousValue, isPercentage, isCurrency }) => {
    const formattedValue = isCurrency ? formatCurrency(Number(value)) : 
                          isPercentage ? formatPercentage(Number(value)) : 
                          value;
    const formattedPrevValue = isCurrency ? formatCurrency(Number(previousValue)) : 
                              isPercentage ? formatPercentage(Number(previousValue)) : 
                              previousValue;
    
    const percentChange = ((Number(value) - Number(previousValue)) / Number(previousValue)) * 100;
    const isPositive = percentChange > 0;

    return (
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="bg-gray-900/50 backdrop-blur-lg rounded-xl p-6 border border-gray-700"
        >
            <h3 className="text-sm font-medium text-gray-400">{title}</h3>
            <div className="mt-2 flex items-baseline">
                <p className="text-2xl font-semibold text-white">
                    {formattedValue}
                </p>
                {!isNaN(percentChange) && (
                    <p className={`ml-2 flex items-baseline text-sm font-semibold ${
                        isPositive ? 'text-green-400' : 'text-red-400'
                    }`}>
                        {isPositive ? <ArrowUpIcon className="w-3 h-3" /> : <ArrowDownIcon className="w-3 h-3" />}
                        <span className="sr-only">{isPositive ? 'Increased' : 'Decreased'} by</span>
                        {Math.abs(percentChange).toFixed(1)}%
                    </p>
                )}
            </div>
            <p className="mt-1 text-sm text-gray-400">
                vs. {formattedPrevValue} last period
            </p>
        </motion.div>
    );
};

const RecentActivity: FC<{
    conversions: AffiliateDashboardData['recentConversions'];
    clicks: AffiliateDashboardData['recentClicks'];
}> = ({ conversions, clicks }) => (
    <div className="mt-8">
        <h2 className="text-xl font-semibold text-white mb-4">Recent Activity</h2>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            <div className="bg-gray-900/50 backdrop-blur-lg rounded-xl p-6 border border-gray-700">
                <h3 className="text-lg font-medium text-white mb-4">Latest Conversions</h3>
                <div className="space-y-4">
                    {conversions.map((conversion) => (
                        <div key={conversion.id} className="flex justify-between items-center py-3 border-b border-gray-700 last:border-0">
                            <div>
                                <p className="text-white">{conversion.conversion_type}</p>
                                <p className="text-sm text-gray-400">
                                    {new Date(conversion.created_at).toLocaleDateString()}
                                </p>
                            </div>
                            <div className="text-right">
                                <p className="text-white">{formatCurrency(conversion.commission_amount)}</p>
                                <p className="text-sm text-green-400">
                                    +{formatCurrency(conversion.commission_amount)}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="bg-gray-900/50 backdrop-blur-lg rounded-xl p-6 border border-gray-700">
                <h3 className="text-lg font-medium text-white mb-4">Latest Clicks</h3>
                <div className="space-y-4">
                    {clicks.map((click) => (
                        <div key={click.id} className="flex justify-between items-center py-3 border-b border-gray-700 last:border-0">
                            <div>
                                <p className="text-white">{new URL(click.referrer_url || '').hostname}</p>
                                <p className="text-sm text-gray-400">
                                    {new Date(click.created_at).toLocaleDateString()}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    </div>
);

const PerformanceDashboard: FC<Props> = ({ affiliateId, tierId }) => {
    const { data, isLoading, error } = useQuery({
        queryKey: ['affiliateDashboard', affiliateId],
        queryFn: () => getAffiliateDashboardData(affiliateId, tierId),
        enabled: !!affiliateId && !!tierId
    });

    if (isLoading) return <LoadingSpinner />;
    if (error) return <div className="text-white">Error loading dashboard data</div>;
    if (!data) return null;

    const { currentMetrics, previousMetrics, recentConversions, recentClicks } = data;

    return (
        <div className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                <MetricCard
                    title="Total Clicks"
                    value={currentMetrics.total_clicks}
                    previousValue={previousMetrics.total_clicks}
                />
                <MetricCard
                    title="Conversions"
                    value={currentMetrics.total_conversions}
                    previousValue={previousMetrics.total_conversions}
                />
                <MetricCard
                    title="Conversion Rate"
                    value={currentMetrics.conversion_rate}
                    previousValue={previousMetrics.conversion_rate}
                    isPercentage
                />
                <MetricCard
                    title="Total Commission"
                    value={currentMetrics.total_commission}
                    previousValue={previousMetrics.total_commission}
                    isCurrency
                />
            </div>
            <RecentActivity
                conversions={recentConversions}
                clicks={recentClicks}
            />
        </div>
    );
};

export default PerformanceDashboard;
