import { supabase } from '@/lib/supabaseClient';
import { 
    AffiliatePerformanceMetrics, 
    CommissionRule, 
    ReferralConversion,
    ReferralClick,
    AffiliateDashboardData
} from '@/types/affiliate';

export const getAffiliatePerformanceMetrics = async (
    affiliateId: string,
    startDate: Date,
    endDate: Date
): Promise<AffiliatePerformanceMetrics | null> => {
    // Get clicks
    const { data: clicks } = await supabase
        .from('referral_clicks')
        .select('created_at')
        .eq('affiliate_id', affiliateId)
        .gte('created_at', startDate.toISOString())
        .lte('created_at', endDate.toISOString());

    // Get conversions
    const { data: conversions } = await supabase
        .from('referral_conversions')
        .select('*')
        .eq('affiliate_id', affiliateId)
        .gte('created_at', startDate.toISOString())
        .lte('created_at', endDate.toISOString());

    const total_clicks = clicks?.length || 0;
    const total_conversions = conversions?.length || 0;
    const total_revenue = conversions?.reduce((sum, conv) => sum + (conv.revenue || 0), 0) || 0;
    const total_commission = conversions?.reduce((sum, conv) => sum + (conv.commission_amount || 0), 0) || 0;

    return {
        id: `${affiliateId}-${startDate.toISOString()}-${endDate.toISOString()}`,
        affiliate_id: affiliateId,
        period_start: startDate.toISOString(),
        period_end: endDate.toISOString(),
        total_clicks,
        total_conversions,
        conversion_rate: total_clicks > 0 ? (total_conversions / total_clicks) * 100 : 0,
        total_revenue,
        total_commission,
        updated_at: new Date().toISOString()
    };
};

export const getActiveCommissionRules = async (affiliateId: string): Promise<CommissionRule[]> => {
    // First get the affiliate's tier ID
    const { data: affiliate } = await supabase
        .from('affiliates')
        .select('tier_id')
        .eq('id', affiliateId)
        .single();

    if (!affiliate?.tier_id) return [];

    // Then get the commission rules for that tier
    const { data, error } = await supabase
        .from('commission_rules')
        .select('*')
        .eq('tier_id', affiliate.tier_id)
        .eq('active', true);

    if (error) throw error;
    return data || [];
};

export const getRecentConversions = async (
    affiliateId: string,
    limit: number = 5
): Promise<ReferralConversion[]> => {
    const { data, error } = await supabase
        .from('referral_conversions')
        .select('*')
        .eq('affiliate_id', affiliateId)
        .order('created_at', { ascending: false })
        .limit(limit);

    if (error) throw error;
    return data;
};

export const getRecentClicks = async (
    affiliateId: string,
    limit: number = 5
): Promise<ReferralClick[]> => {
    const { data, error } = await supabase
        .from('referral_clicks')
        .select('*')
        .eq('affiliate_id', affiliateId)
        .order('created_at', { ascending: false })
        .limit(limit);

    if (error) throw error;
    return data;
};

export const getAffiliateDashboardData = async (
    affiliateId: string
): Promise<AffiliateDashboardData> => {
    const now = new Date();
    const currentPeriodStart = new Date(now.getFullYear(), now.getMonth(), 1);
    const currentPeriodEnd = new Date(now.getFullYear(), now.getMonth() + 1, 0);
    
    const previousPeriodStart = new Date(now.getFullYear(), now.getMonth() - 1, 1);
    const previousPeriodEnd = new Date(now.getFullYear(), now.getMonth(), 0);

    const [
        currentMetrics,
        previousMetrics,
        activeCommissionRules,
        recentConversions,
        recentClicks
    ] = await Promise.all([
        getAffiliatePerformanceMetrics(affiliateId, currentPeriodStart, currentPeriodEnd),
        getAffiliatePerformanceMetrics(affiliateId, previousPeriodStart, previousPeriodEnd),
        getActiveCommissionRules(affiliateId),
        getRecentConversions(affiliateId),
        getRecentClicks(affiliateId)
    ]);

    return {
        currentMetrics: currentMetrics!,
        previousMetrics: previousMetrics!,
        activeCommissionRules,
        recentConversions,
        recentClicks
    };
};
