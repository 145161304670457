import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '@/lib/supabaseClient';
import { toast } from 'react-hot-toast';

export default function AffiliateVerify() {
    const navigate = useNavigate();

    useEffect(() => {
        const handleEmailVerification = async () => {
            try {
                // Get the current session
                const { data: { session }, error: sessionError } = await supabase.auth.getSession();
                if (sessionError) throw sessionError;

                if (!session?.user) {
                    // If no session, the user might need to complete the email verification
                    // Let Supabase handle the verification and then redirect
                    const { error: verifyError } = await supabase.auth.verifyOtp({
                        type: 'email',
                        token_hash: window.location.hash
                    });
                    
                    if (verifyError) throw verifyError;
                }

                // At this point, the email should be verified
                // Check if they have an affiliate record
                const { data: affiliate } = await supabase
                    .from('affiliates')
                    .select('id, status')
                    .eq('user_id', session?.user?.id)
                    .single();

                if (!affiliate) {
                    // Generate a unique referral code based on timestamp and random string
                    const timestamp = Date.now().toString(36);
                    const randomStr = Math.random().toString(36).substring(2, 6);
                    const referralCode = `${timestamp}-${randomStr}`;

                    // Create a new affiliate record with all required fields
                    const { error: createError } = await supabase
                        .from('affiliates')
                        .insert([
                            {
                                user_id: session?.user?.id,
                                status: 'active',
                                tier: 'basic', // Start with basic tier
                                referral_code: referralCode,
                                total_earnings: 0,
                                created_at: new Date().toISOString(),
                                updated_at: new Date().toISOString()
                            }
                        ]);

                    if (createError) throw createError;
                }

                toast.success('Email verified successfully! You can now log in.');
                navigate('/affiliate/login');
            } catch (error) {
                console.error('Verification error:', error);
                toast.error('Error verifying email. Please try again.');
                navigate('/affiliate/login');
            }
        };

        handleEmailVerification();
    }, [navigate]);

    return (
        <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-gray-900 to-gray-800">
            <div className="text-center">
                <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-white mx-auto mb-4"></div>
                <h2 className="text-xl text-white">Verifying your email...</h2>
            </div>
        </div>
    );
}
