import { Link, useLocation, useNavigate } from 'react-router-dom';
import { HomeIcon, HashtagIcon, PhotoIcon, ChartBarIcon, ArrowRightOnRectangleIcon } from '@heroicons/react/24/outline';
import { supabase } from '@/lib/supabaseClient';
import { toast } from 'react-hot-toast';

const navItems = [
  {
    name: 'Dashboard',
    href: '/affiliate/dashboard',
    icon: HomeIcon,
  },
  {
    name: 'Referral Code',
    href: '/affiliate/code',
    icon: HashtagIcon,
  },
  {
    name: 'Promotional Assets',
    href: '/affiliate/assets',
    icon: PhotoIcon,
  },
  {
    name: 'Performance',
    href: '/affiliate/performance',
    icon: ChartBarIcon,
  },
];

export function AffiliateNav() {
  const location = useLocation();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      // First navigate to login page
      navigate('/affiliate/login', { replace: true });
      
      // Then sign out
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      
      toast.success('Logged out successfully');
    } catch (error) {
      console.error('Logout error:', error);
      toast.error('Failed to log out');
    }
  };

  return (
    <nav className="h-screen w-64 bg-gray-900/50 border-r border-gray-800">
      <div className="flex flex-col h-full">
        <div className="flex-1">
          <div className="mb-8 p-4">
            <h2 className="text-xl font-bold text-white px-4">Affiliate Portal</h2>
          </div>
          <div className="flex flex-col gap-1 px-4">
            {navItems.map((item) => {
              const isActive = location.pathname === item.href;
              return (
                <Link
                  key={item.name}
                  to={item.href}
                  className={`flex items-center gap-3 px-4 py-2.5 rounded-lg transition-colors ${
                    isActive
                      ? 'bg-primary text-white'
                      : 'text-gray-400 hover:bg-gray-800/50 hover:text-white'
                  }`}
                >
                  <item.icon className="w-5 h-5" />
                  <span>{item.name}</span>
                </Link>
              );
            })}
          </div>
        </div>
        <div className="p-4">
          <button
            onClick={handleLogout}
            className="flex items-center gap-3 px-4 py-2.5 rounded-lg transition-colors w-full text-gray-400 hover:bg-gray-800/50 hover:text-white"
          >
            <ArrowRightOnRectangleIcon className="w-5 h-5" />
            <span>Logout</span>
          </button>
        </div>
      </div>
    </nav>
  );
}
