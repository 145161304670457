import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { Save, ArrowLeft, Upload, X, Play, Pause } from 'lucide-react';
import { createPost, updatePost, getPost } from '@/services/api/blog';
import { uploadFile } from '@/services/api/storage';
import type { BlogPost, GeneratedBlogContent } from '@/types/blog';
import MDEditor from '@uiw/react-md-editor';
import AIBlogAssistant from '@/admin/features/blog/AIBlogAssistant';
import ImageGenerator, { ImageGeneratorRef } from '@/admin/features/blog/ImageGenerator';

const BlogEditor: React.FC = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const audioRef = useRef<HTMLAudioElement>(null);
    const [post, setPost] = useState<Partial<BlogPost>>({
        title: '',
        content: '',
        excerpt: '',
        status: 'draft' as const,
        meta_title: '',
        meta_description: '',
        featured_image: '',
        audio_url: '',
        audio_duration: undefined,
    });

    const [isDragging, setIsDragging] = useState(false);
    const [aiTopic, setAITopic] = useState('');

    const handleDragOver = (e: React.DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(true);
    };

    const handleDragEnter = (e: React.DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(true);
    };

    const handleDragLeave = (e: React.DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
        
        // Only set isDragging to false if we're leaving the main container
        const rect = e.currentTarget.getBoundingClientRect();
        const x = e.clientX;
        const y = e.clientY;
        
        if (
            x <= rect.left ||
            x >= rect.right ||
            y <= rect.top ||
            y >= rect.bottom
        ) {
            setIsDragging(false);
        }
    };

    const handleDrop = async (e: React.DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);

        const files = Array.from(e.dataTransfer.files);
        if (files.length === 0) return;

        const file = files[0];
        const isImage = file.type.startsWith('image/');
        const isAudio = file.type.startsWith('audio/');

        if (!isImage && !isAudio) {
            toast.error('Please drop an image or audio file');
            return;
        }

        if (isImage) {
            await handleImageUpload(file);
        } else if (isAudio) {
            await handleAudioUpload(file);
        }
    };

    useEffect(() => {
        if (id) {
            loadPost();
        }
    }, [id]);

    const loadPost = async () => {
        if (!id) return;
        setLoading(true);
        try {
            const { data, error } = await getPost(id);
            if (error) throw error;
            if (data) {
                setPost({
                    ...data,
                    meta_title: data.meta_title || '',
                    meta_description: data.meta_description || '',
                    featured_image: data.featured_image || '',
                    audio_url: data.audio_url || '',
                    audio_duration: data.audio_duration,
                });
            }
        } catch (error) {
            console.error('Error loading post:', error);
            toast.error('Failed to load post');
            navigate('/admin/blog');
        } finally {
            setLoading(false);
        }
    };

    const handleAudioUpload = async (file: File) => {
        setLoading(true);
        try {
            const filePath = `blog-audio/${Date.now()}-${file.name}`;
            const { url, error: uploadError } = await uploadFile(filePath, file);
            if (uploadError) throw uploadError;
            if (!url) throw new Error('Failed to get upload URL');

            const audio = new Audio();
            audio.src = url;
            
            await new Promise((resolve) => {
                audio.addEventListener('loadedmetadata', () => {
                    setPost(prev => ({
                        ...prev,
                        audio_url: url || undefined,
                        audio_duration: Math.round(audio.duration)
                    }));
                    resolve(undefined);
                });
            });

            toast.success('Audio uploaded successfully');
        } catch (error) {
            console.error('Audio upload error:', error);
            toast.error('Failed to upload audio');
        } finally {
            setLoading(false);
        }
    };

    const handleImageUpload = async (file: File) => {
        setLoading(true);
        try {
            const fileName = `${Date.now()}-${file.name}`;
            const filePath = `blog-images/${fileName}`;
            const { url, error: uploadError } = await uploadFile(filePath, file);
            if (uploadError) throw uploadError;
            if (!url) throw new Error('Failed to get upload URL');

            setPost(prev => ({
                ...prev,
                featured_image: url
            }));

            toast.success('Image uploaded successfully');
        } catch (error) {
            console.error('Image upload error:', error);
            toast.error('Failed to upload image');
        } finally {
            setLoading(false);
        }
    };

    const toggleAudioPlayback = () => {
        if (!audioRef.current) return;

        if (isPlaying) {
            audioRef.current.pause();
        } else {
            audioRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    const removeAudio = () => {
        setPost(prev => ({
            ...prev,
            audio_url: undefined,
            audio_duration: undefined
        }));
    };

    const removeImage = () => {
        setPost(prev => ({
            ...prev,
            featured_image: undefined
        }));
    };

    const formatDuration = (seconds?: number) => {
        if (!seconds) return '0:00';
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = Math.round(seconds % 60);
        return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
    };

    const handleSave = async (status?: BlogPost['status']) => {
        if (!post.title) {
            toast.error('Please enter a title');
            return;
        }

        if (!post.content) {
            toast.error('Please enter content');
            return;
        }

        setSaving(true);
        try {
            const postData = {
                title: post.title,
                content: post.content,
                status: status || post.status || 'draft',
                slug: post.slug || post.title.toLowerCase().replace(/[^a-z0-9]+/g, '-').replace(/(^-|-$)/g, ''),
                excerpt: post.excerpt || '',
                meta_title: post.meta_title || '',
                meta_description: post.meta_description || '',
                featured_image: post.featured_image,
                audio_url: post.audio_url,
                audio_duration: post.audio_duration,
            };

            const { error } = id
                ? await updatePost(id, postData)
                : await createPost(postData);

            if (error) throw error;

            toast.success(`Post ${id ? 'updated' : 'created'} successfully`);
            if (!id) {
                navigate('/admin/blog');
            }
        } catch (error) {
            console.error('Error saving post:', error);
            toast.error('Failed to save post');
        } finally {
            setSaving(false);
        }
    };

    const imageGeneratorRef = useRef<ImageGeneratorRef>(null);

    const handleAIGenerated = async (content: GeneratedBlogContent) => {
        setPost(prev => ({
            ...prev,
            ...content
        }));

        // Automatically generate the image if we have an image prompt
        if (content.image_prompt && imageGeneratorRef.current) {
            try {
                const imageUrl = await imageGeneratorRef.current.generateImage(content.image_prompt);
                if (imageUrl) {
                    setPost(prev => ({
                        ...prev,
                        featured_image: imageUrl
                    }));
                }
            } catch (error) {
                console.error('Failed to generate image:', error);
                toast.error('Failed to generate featured image');
            }
        }
    };

    const handleTopicChange = (topic: string) => {
        setAITopic(topic);
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-primary-500"></div>
            </div>
        );
    }

    return (
        <div 
            className="container mx-auto px-4 py-8"
            onDragOver={handleDragOver}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            style={{ minHeight: '100vh' }}
        >
            {isDragging && (
                <div className="fixed inset-0 bg-primary-500/20 backdrop-blur-sm flex items-center justify-center z-50">
                    <div className="bg-gray-800 rounded-lg p-8 text-center">
                        <Upload className="w-12 h-12 text-primary-500 mx-auto mb-4" />
                        <p className="text-lg text-white">Drop your file here</p>
                        <p className="text-sm text-gray-400 mt-2">Supported: Images and Audio files</p>
                    </div>
                </div>
            )}
            <div className="mb-6 flex justify-between items-center">
                <button
                    onClick={() => navigate('/admin/blog')}
                    className="flex items-center gap-2 text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
                >
                    <ArrowLeft className="w-4 h-4" />
                    Back to Posts
                </button>
                <div className="flex gap-4">
                    <button
                        onClick={() => handleSave('draft')}
                        disabled={saving}
                        className="px-4 py-2 bg-gray-100 dark:bg-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600 
                            text-gray-700 dark:text-gray-200 rounded-lg flex items-center gap-2 transition-colors"
                    >
                        Save as Draft
                    </button>
                    <ImageGenerator
                        ref={imageGeneratorRef}
                        title={post.title || aiTopic}
                        onImageGenerated={(url) => {
                            setPost(prev => ({
                                ...prev,
                                featured_image: url
                            }));
                        }}
                    />
                    <button
                        onClick={() => handleSave('published')}
                        disabled={saving}
                        className="px-4 py-2 bg-primary-500 hover:bg-primary-600 text-white rounded-lg flex items-center gap-2"
                    >
                        <Save className="w-4 h-4" />
                        {saving ? 'Saving...' : 'Publish'}
                    </button>
                </div>
            </div>

            <AIBlogAssistant
                onGenerate={(blogData: GeneratedBlogContent) => {
                    handleAIGenerated(blogData);
                }}
                onTopicChange={(topic: string) => {
                    handleTopicChange(topic);
                }}
            />

            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
                <div className="space-y-6">
                    <div>
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                            Title
                        </label>
                        <input
                            type="text"
                            value={post.title}
                            onChange={(e) => setPost({ ...post, title: e.target.value })}
                            className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-primary-500
                                dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                            placeholder="Enter post title"
                        />
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                            Excerpt
                        </label>
                        <textarea
                            value={post.excerpt}
                            onChange={(e) => setPost({ ...post, excerpt: e.target.value })}
                            className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-primary-500
                                dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                            rows={3}
                            placeholder="Enter post excerpt"
                        />
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                            Content
                        </label>
                        <div data-color-mode="dark">
                            <MDEditor
                                value={post.content}
                                onChange={(value) => {
                                    setPost(prev => ({ ...prev, content: value || '' }));
                                }}
                                preview="edit"
                                height={500}
                            />
                        </div>
                    </div>

                    <div className="flex flex-col space-y-2">
                        <label className="text-sm font-medium text-gray-200">Featured Image</label>
                        <div 
                            className={`
                                relative border-2 border-dashed rounded-lg p-4 text-center
                                ${post.featured_image ? 'border-primary-500' : 'border-gray-600'}
                                hover:border-primary-500 transition-colors duration-200
                            `}
                        >
                            {post.featured_image ? (
                                <div className="relative aspect-video">
                                    <img
                                        src={post.featured_image}
                                        alt="Featured"
                                        className="w-full h-full object-cover rounded-lg"
                                    />
                                    <button
                                        onClick={removeImage}
                                        className="absolute top-2 right-2 p-1 bg-red-500 rounded-full text-white hover:bg-red-600 transition-colors"
                                    >
                                        <X className="w-4 h-4" />
                                    </button>
                                </div>
                            ) : (
                                <div className="flex flex-col items-center justify-center gap-4">
                                    <div className="flex items-center justify-center gap-4">
                                        <label
                                            htmlFor="image-upload"
                                            className="inline-flex items-center gap-2 px-4 py-2 bg-gray-100 dark:bg-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600 
                                                text-gray-700 dark:text-gray-200 rounded-lg cursor-pointer transition-colors text-sm font-medium"
                                        >
                                            <Upload className="w-4 h-4" />
                                            Browse Files
                                        </label>
                                        <input
                                            id="image-upload"
                                            type="file"
                                            accept="image/jpeg,image/png,image/gif,image/webp"
                                            onChange={(e) => {
                                                const file = e.target.files?.[0];
                                                if (file) handleImageUpload(file);
                                            }}
                                            className="hidden"
                                        />
                                        <span className="text-sm text-gray-500">or drag and drop</span>
                                    </div>
                                    <span className="text-xs text-gray-500">
                                        Supported formats: JPEG, PNG, GIF, WebP (max 5MB)
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="flex flex-col space-y-2">
                        <label className="text-sm font-medium text-gray-200">Audio Version</label>
                        <div 
                            className={`
                                relative border-2 border-dashed rounded-lg p-4 text-center
                                ${post.audio_url ? 'border-primary-500' : 'border-gray-600'}
                                hover:border-primary-500 transition-colors duration-200
                            `}
                        >
                            {post.audio_url ? (
                                <div className="flex items-center justify-between gap-4 px-4">
                                    <div className="flex items-center gap-4">
                                        <button
                                            onClick={toggleAudioPlayback}
                                            className="p-2 rounded-full bg-primary-500 hover:bg-primary-600 text-white transition-colors"
                                        >
                                            {isPlaying ? (
                                                <Pause className="w-4 h-4" />
                                            ) : (
                                                <Play className="w-4 h-4" />
                                            )}
                                        </button>
                                        <span className="text-sm text-gray-600 dark:text-gray-300">
                                            {formatDuration(post.audio_duration)}
                                        </span>
                                        <audio ref={audioRef} src={post.audio_url} />
                                    </div>
                                    <button
                                        onClick={removeAudio}
                                        className="p-1 bg-red-500 rounded-full text-white hover:bg-red-600 transition-colors"
                                    >
                                        <X className="w-4 h-4" />
                                    </button>
                                </div>
                            ) : (
                                <div className="flex flex-col items-center justify-center gap-4">
                                    <div className="flex items-center justify-center gap-4">
                                        <label
                                            htmlFor="audio-upload"
                                            className="inline-flex items-center gap-2 px-4 py-2 bg-gray-100 dark:bg-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600 
                                                text-gray-700 dark:text-gray-200 rounded-lg cursor-pointer transition-colors text-sm font-medium"
                                        >
                                            <Upload className="w-4 h-4" />
                                            Browse Files
                                        </label>
                                        <input
                                            id="audio-upload"
                                            type="file"
                                            accept="audio/wav,audio/mp3,audio/mpeg,audio/ogg"
                                            onChange={(e) => {
                                                const file = e.target.files?.[0];
                                                if (file) handleAudioUpload(file);
                                            }}
                                            className="hidden"
                                        />
                                        <span className="text-sm text-gray-500">or drag and drop</span>
                                    </div>
                                    <span className="text-xs text-gray-500">
                                        Supported formats: WAV, MP3, OGG (max 50MB)
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div>
                            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                                Meta Title
                            </label>
                            <input
                                type="text"
                                value={post.meta_title}
                                onChange={(e) => setPost({ ...post, meta_title: e.target.value })}
                                className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-primary-500
                                    dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                                placeholder="Enter meta title"
                            />
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                                Meta Description
                            </label>
                            <textarea
                                value={post.meta_description}
                                onChange={(e) => setPost({ ...post, meta_description: e.target.value })}
                                className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-primary-500
                                    dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                                rows={3}
                                placeholder="Enter meta description"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BlogEditor;
